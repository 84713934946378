import Axios from 'axios'
import { BIZ_URL, CHAT_IP } from '../utils/UseFunc/UseFunction';

/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

/** @type { () => AxiosInstance } */
export const callApi = () => {
  const getStorage = sessionStorage.getItem('login') !== null && sessionStorage.getItem('login');
  const getToken = JSON.parse(getStorage);

  return Axios.create({
    baseURL: BIZ_URL, //테스트 l서버
    headers: {
      'Authorization': getToken ? getToken.token : '',
    }
  });
}

export const API = () => {
  return Axios.create({
    baseURL: CHAT_IP
  });
}


import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { takeLatest } from 'redux-saga/effects';
import createSaga, { createActionTypes } from '../../utils/Request/RequestCreateSaga';
import * as authAPI from '../../api/AuthApi';
import * as memberAPI from '../../api/MemberApi';

const CHANGE_FIELD = 'auth/CHANGE_FIELD';
const INITIALIZE_FORM = 'auth/INITIALIZE_FORM';
const ACTION_SET_COMPANY_FORM = 'auth/ACTION_SET_COMPANY_FORM';
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createActionTypes('auth/LOGIN');
const [ACTION_SET_COMPANY, ACTION_SET_COMPANY_SUCCESS, ACTION_SET_COMPANY_FAILURE] = createActionTypes('auth/ACTION_SET_COMPANY');
const [ACTION_GET_COMPANY, ACTION_GET_COMPANY_SUCCESS] = createActionTypes('auth/ACTION_GET_COMPANY');
const [ACTION_DELETE_COMPANY, ACTION_DELETE_COMPANY_SUCCESS] = createActionTypes('auth/ACTION_DELETE_COMPANY');
const [ACTION_FIND_PASSWORD, ACTION_FIND_PASSWORD_SUCCESS] = createActionTypes('auth/ACTION_FIND_PASSWORD');
const [ACTION_CHANGE_PASSWORD, ACTION_CHANGE_PASSWORD_SUCCESS, ACTION_CHANGE_PASSWORD_FAILURE] = createActionTypes('auth/ACTION_CHANGE_PASSWORD');

export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, name, value }) => ({
    form,
    name,
    value,
  }),
);

export const initializeForm = createAction(INITIALIZE_FORM, form => form);
export const SET_COMPANY_FORM = createAction(ACTION_SET_COMPANY_FORM, form => form);
export const SET_FIND_PASSWORD = createAction(ACTION_FIND_PASSWORD, ({ CompSeq, FindId, FindEmail }) => ({
  CompSeq,
  FindId,
  FindEmail,
}));
export const SET_CHANGE_PASSWORD = createAction(ACTION_CHANGE_PASSWORD, form => form);
export const SET_COMPANY = createAction(ACTION_SET_COMPANY, form => form);
export const DELETE_COMPANY = createAction(ACTION_DELETE_COMPANY, form => form);
export const GET_COMPANY_LIST = createAction(ACTION_GET_COMPANY, ({ type, codeName }) => ({ type, codeName }));
export const login = createAction(LOGIN, ({ CompSeq, AuthId, AuthPw }) => ({
  CompSeq,
  AuthId,
  AuthPw,
}));

const loginSaga = createSaga(LOGIN, authAPI.Login);
const SAGA_SET_FIND_PASSWORD = createSaga(ACTION_FIND_PASSWORD, authAPI.findPw);
const SAGA_SET_CHANGE_PASSWORD = createSaga(ACTION_CHANGE_PASSWORD, memberAPI.changePassword);
const SAGA_SET_COMPANY = createSaga(ACTION_SET_COMPANY, authAPI.setCompany);
const SAGA_GET_COMPANY = createSaga(ACTION_GET_COMPANY, authAPI.getCompany, false, true);
const SAGA_DELETE_COMPANY = createSaga(ACTION_DELETE_COMPANY, authAPI.deleteCompany);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(ACTION_FIND_PASSWORD, SAGA_SET_FIND_PASSWORD);
  yield takeLatest(ACTION_CHANGE_PASSWORD, SAGA_SET_CHANGE_PASSWORD);
  yield takeLatest(ACTION_SET_COMPANY, SAGA_SET_COMPANY);
  yield takeLatest(ACTION_GET_COMPANY, SAGA_GET_COMPANY);
  yield takeLatest(ACTION_DELETE_COMPANY, SAGA_DELETE_COMPANY);
}

const initialState = {
  login: {
    AuthStore: '',
    AuthId: '',
    AuthPw: '',
  },
  findForm: {
    FindStore: '',
    FindId: '',
    FindEmail: '',
  },
  changePw: {
    oldPw: '',
    newPw: '',
    newPwRe: '',
  },
  company: {
    CompId: '',
    CompName: '',
    CompCode: '',
    CompEmail: '',
    CompTel: '',
    CompAdr: '',
    CompExpDte: '',
    CompSeq: 0,
    TypeCheck: false,
  },
  companyList: {},
  companySearch: {
    type: '',
    codeName: '',
  },
  auth: null,
  findAuth: false,
  changeAuth: false,
  companyAuth: false,
  companyError: null,
  authError: null,
  companyDelete: null,
  changePasswordError: null,
};

const auth = handleActions({
  [CHANGE_FIELD]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [INITIALIZE_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_SET_COMPANY_FORM]: (state, { payload: form }) => ({
    ...state,
    company: {
      CompId: form.id,
      CompName: form.compName,
      CompCode: form.compCode,
      CompEmail: form.email,
      CompTel: form.tel,
      CompAdr: form.addr,
      CompExpDte: form.expDte !== ' ' ? Date.parse(form.expDte) : '',
      CompSeq: form.compSeq,
      TypeCheck: form.check,
    },
  }),
  [ACTION_FIND_PASSWORD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    findAuth: data === 200,
  }),
  [ACTION_CHANGE_PASSWORD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    changeAuth: data === 200,
  }),
  [ACTION_CHANGE_PASSWORD_FAILURE]: (state, { payload: error }) => ({
    ...state,
    changePasswordError: error.response.status,
  }),
  [ACTION_GET_COMPANY_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    companyList: data,
  }),
  [ACTION_DELETE_COMPANY_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    companyDelete: data === 200,
  }),
  [ACTION_SET_COMPANY_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    companyAuth: data === 200,
  }),
  [ACTION_SET_COMPANY_FAILURE]: (state, { payload: error }) => ({
    ...state,
    companyError: error.response.status,
  }),
  [LOGIN_SUCCESS]: (state, { payload: auth }) => ({
    ...state,
    authError: null,
    auth,
  }),
  [LOGIN_FAILURE]: (state, { payload: error }) => ({
    ...state,
    authError: error,
  }),
}, initialState);

export default auth;
import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../lib/styles/img/background/bg_logo_osd.png';
import { GNB_ADMIN_MENU } from '../../../lib/store/Gnb';
import { MdKeyboardArrowRight } from 'react-icons/all';
import { AdminColor } from '../../../lib/styles/palette';

const Container = styled.div`
  width: 275px;
  height: 100vh;
  background-color: ${AdminColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1000;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
  }

  .active .rotateArrow,
  .active .sec-active {
    color: #fff !important;
  }

  ul {
    width: 100%;
    display: block;

    li {
      a {
        color: #9ba7b5;
        align-items: center;
      }

      &:hover > a {
        color: #c5eff7;
      }

      .side-nav-second-level {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.448s ease-out;
      }

      ul a span {
        margin-left: 36px;
      }

      a {
        display: flex;
        padding: 10px 30px;

        &.side-nav-active {
          color: #e8e8e8 !important;
        }

        &.rotateArrow {
          .arrow {
            transform: rotate(90deg);
          }

          ~ .side-nav-second-level {
            max-height: 500px;
            transition: max-height 0.4s ease-in;
          }
        }

        i {
          transition: all 0.2s ease-in-out;
        }

        span {
          margin-left: 15px;
          font-size: 14px;
        }

        .arrow {
          margin-left: auto;
        }
      }
    }
  }
`;

const Icon = styled.i``;

const MainMenu = () => {
  const onClick = e => {
    const li = e.target.closest('li');
    const ul = li.parentNode;

    if (li.childNodes[0].classList.contains('rotateArrow')) {
      ul.childNodes.forEach(child => {
        child.childNodes[0].classList.remove('rotateArrow');
      });
    } else {
      ul.childNodes.forEach(child => {
        child.childNodes[0].classList.remove('rotateArrow');
      });

      if (li.childNodes[1]) {
        li.childNodes[0].classList.add('rotateArrow');
        li.classList.add('active');
      }
    }
  };

  return (
    <Container>
      <h1>
        <Link to="/admin/list">
          <img src={logo} alt="logo" />
        </Link>
      </h1>

      <ul onClick={onClick} className="side-nav-bar">
        {GNB_ADMIN_MENU.map((value, index) => {
          const active = window.location.pathname === value.link;

          return (
            <li
              className={`side-nav-item ${active ? 'active' : ''}`}
              key={index}
            >
              <NavLink
                to={value.link}
                onClick={e => value.second && e.preventDefault()}
                className={active ? 'rotateArrow' : ''}
              >
                <Icon>
                  {value.icon}
                </Icon>

                <span>
                  {value.name}
                </span>

                {value.second &&
                  <Icon className="arrow">
                    <MdKeyboardArrowRight />
                  </Icon>
                }
              </NavLink>

              {value.second &&
                <ul className="side-nav-second-level">
                  {value.second.map((secValue, secIndex) => {
                    return (
                      <li key={secIndex}>
                        <NavLink
                          to={secValue.link}
                          className={URL() === secValue.link ? 'sec-active' : ''}
                        >
                          <span>{secValue.name}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              }
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default MainMenu;

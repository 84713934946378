import { Bar, Doughnut, Line, Scatter, HorizontalBar } from 'react-chartjs-2';
import React from 'react';
import i18next from 'i18next';
import palette, { FemaleColor, MaleColor } from '../../lib/styles/palette';
import { getGaussianFuc, MathRound } from '../../utils/UseFunc/UseFunction';
import {
  GET_CHART_SHADOW,
  GET_UNIT_PERCENTAGE,
  GET_UNIT_WEIGHT,
  SET_UNIT_WEIGHT,
} from '../../utils/UseFunc/UseStorage';
import 'chartjs-plugin-annotation';

const defTooltips = {
  mode: 'index',
  intersect: false,
  bodyFontFamily: 'Poppins, Helvetica, sans-serif',
  titleFontFamily: 'Poppins, Helvetica, sans-serif',
  backgroundColor: '#535353',
};

export const CHART_MANAGER_TOTAL_USER_GENDER = ({data}) => {
  return (
    <Doughnut
      data={{
        labels: [
          i18next.t('GenderF'),
          i18next.t('GenderM')
        ],
        datasets: [
          {
            data: [data.femaleCnt, data.maleCnt],
            borderWidth: 0,
            hoverBorderWidth: 1,
            backgroundColor: [
              palette.ChartCol01,
              palette.ChartCol02
            ],
            fill: true
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        cutoutPercentage: 70,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
      }}
    />
  );
}

export const CHART_MANAGER_TOTAL_AGE = ({data}) => {
  return (
    <HorizontalBar
      data={{
        labels: ['1 ~ 17', '18 ~ 24', '25 ~ 44', '45 ~ 54', '55 ~ 99'],
        datasets: [
          {
            label: i18next.t('GenderF'),
            data: data.female,
            backgroundColor: FemaleColor,
            barPercentage: 0.3,
            ...GET_CHART_SHADOW
          },
          {
            label: i18next.t('GenderM'),
            data: data.male,
            backgroundColor: MaleColor,
            barPercentage: 0.3,
            ...GET_CHART_SHADOW
          }
        ]
      }}
      options={{
        tooltips: defTooltips,
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
            },
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
            },
          }],
        },
      }}
    />
  );
}

export const CHART_MANAGER_TOTAL_WEIGHT = ({data}) => {
  return (
    <Bar
      data={{
        labels: ['40 KG', '50 KG', '60 KG', '70 KG', '80 KG', '90 KG', '100 KG'],
        datasets: [
          {
            label: i18next.t('GenderF'),
            data: data.female,
            backgroundColor: FemaleColor,
            barPercentage: 0.3,
            ...GET_CHART_SHADOW
          },
          {
            label: i18next.t('GenderM'),
            data: data.male,
            backgroundColor: MaleColor,
            barPercentage: 0.3,
            ...GET_CHART_SHADOW
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: defTooltips,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              fontFamily: 'Poppins, Helvetica, sans-serif',
              userCallback: function(label, index, labels) {
                // Total Age 그래프 Y축 Label 소수점 제거
                return label % 1 === 0 ? label : '';
              },
            },
          }],
        },
      }}
    />
  );
}

export const CHART_MANAGER_SCATTER_PLOT = ({data}) => {
  return (
    <Scatter
      data={{
        datasets: [
          {
            label: i18next.t('GenderF'),
            id: data.infoF,
            data: data.dataF,
            backgroundColor: FemaleColor,
          },
          {
            label: i18next.t('GenderM'),
            id: data.infoM,
            data: data.dataM,
            backgroundColor: MaleColor
          },
          {
            type: 'line',
            label: i18next.t('AnalysisHeader02'),
            data: data.avgBfm,
            borderColor: palette.ChartCol04,
            fill: false,
            pointRadius: 0,
            borderWidth: 1.5,
          },
          {
            type: 'line',
            label: i18next.t('AnalysisHeader03'),
            data: data.avgSmm,
            borderColor: palette.ChartCol04,
            fill: false,
            pointRadius: 0,
            borderWidth: 1.5,
          }
        ]
      }}
      options={{
        tooltips: {
          titleFontFamily: 'Poppins, Helvetica, sans-serif',
          bodyFontFamily: 'Poppins, Helvetica, sans-serif',
          backgroundColor: '#535353',
          titleFontSize: 14,
          callbacks: {
            title: (tooltipItem, data) => {
              return data.datasets[tooltipItem[0].datasetIndex].id[tooltipItem[0].index].name
            },
            label: (tooltipItem, data) => {
              const bfm = i18next.t('AnalysisHeader02') + ' : ' + tooltipItem.xLabel + GET_UNIT_WEIGHT();
              const smm = i18next.t('AnalysisHeader03') + ' : ' + tooltipItem.yLabel + GET_UNIT_WEIGHT();
              return [bfm, smm];
            },
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
              /* Age Max Value / 4 */
              stepSize: SET_UNIT_WEIGHT(70) / 10,
              max: SET_UNIT_WEIGHT(70),
              min: 0,
            },
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
              /* Age Max Value / 5 */
              stepSize: SET_UNIT_WEIGHT(70) / 4,
              max: SET_UNIT_WEIGHT(70),
              userCallback: function(label, index, labels) {
                // Total Age 그래프 Y축 Label 소수점 제거
                return MathRound(label, 0);
              },
            },
          }],
        },
      }}
    />
  );
}

export const CHART_MANAGER_BODY_COMP = ({data}) => {
  return (
    <Line
      data={{
        labels: data.date,
        datasets: [
          {
            label: i18next.t('AnalysisHeader02'),
            data: data.wt,
            borderColor: palette.ChartCol01,
            backgroundColor: palette.ChartCol01Alpha,
            fill: '+1',
            borderWidth: 4,
            ...GET_CHART_SHADOW
          },
          {
            label: i18next.t('AnalysisHeader03'),
            data: data.bfm,
            borderColor: palette.ChartCol02,
            backgroundColor: palette.ChartCol02Alpha,
            fill: '+1',
            borderWidth: 4,
            ...GET_CHART_SHADOW
          },
          {
            label: i18next.t('OtherCtg05'),
            data: data.smm,
            borderColor: palette.ChartCol03,
            backgroundColor: palette.ChartCol03Alpha,
            borderWidth: 4,
            ...GET_CHART_SHADOW
          }
        ]
      }}
      options={{
        tooltips: {
          mode: 'index',
          intersect: false,
          bodyFontFamily: 'Poppins, Helvetica, sans-serif',
          titleFontFamily: 'Poppins, Helvetica, sans-serif',
          backgroundColor: '#535353',
          callbacks: {
            label: (tooltipItem, data) => {
              let value;
              if(tooltipItem.datasetIndex === 0) {
                value = i18next.t('AnalysisHeader02') + ' : ' + MathRound(data.datasets[0].data[tooltipItem.index] - data.datasets[1].data[tooltipItem.index], 10)
              } else if(tooltipItem.datasetIndex === 1) {
                value = i18next.t('AnalysisHeader03') + ' : ' + MathRound(data.datasets[1].data[tooltipItem.index] - data.datasets[2].data[tooltipItem.index], 10);
              } else {
                value = i18next.t('OtherCtg05') + ' : ' + MathRound(data.datasets[2].data[tooltipItem.index], 10);
              }

              return value + GET_UNIT_WEIGHT().toUpperCase();
            },
            afterLabel: function(tooltipItem, data) {
              if(tooltipItem.datasetIndex === 2) {
                return i18next.t('AnalysisHeader12') + ' : ' +
                  MathRound(
                    ((data.datasets[0].data[tooltipItem.index] - data.datasets[1].data[tooltipItem.index]) /
                    (data.datasets[1].data[tooltipItem.index] - data.datasets[2].data[tooltipItem.index])) || 0, 100
                  );
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
              userCallback: function(label, index, labels) {
                // Total Age 그래프 Y축 Label 소수점 제거

                return label;
              },
            },
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
              beginAtZero: true,
              min: 0,
            },
          }],
        },
      }}
    />
  );
}

export const CHART_MANAGER_MISSION = ({data}) => {
  return (
    <Doughnut
      data={{
        labels: [
          i18next.t('OtherCtg06'),
          i18next.t('GenderF'),
        ],
        datasets: [
          {
            data: [data.avgAchieveRate, 100 - data.avgAchieveRate],
            borderWidth: 0,
            hoverBorderWidth: 1,
            backgroundColor: [
              palette.ChartBodyTrend04
            ],
            fill: true,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled : false
        },
        cutoutPercentage: 0,
      }}
    />
  );
}

export const CHART_MANAGER_GOAL_ACHIEVE = ({data}) => {
  return (
    <Line
      data={{
        labels: data.date,
        datasets: [
          {
            label: i18next.t('AnalysisHeader02'),
            data: data.bfm,
            borderColor: palette.Color_BFP,
            fill: false,
            ...GET_CHART_SHADOW
          },
          {
            label: i18next.t('AnalysisHeader03'),
            data: data.smm,
            borderColor: palette.ChartCol02,
            fill: false,
            ...GET_CHART_SHADOW
          }
        ]
      }}
      options={{
        tooltips: {
          mode: 'index',
          intersect: false,
          bodyFontFamily: 'Poppins, Helvetica, sans-serif',
          titleFontFamily: 'Poppins, Helvetica, sans-serif',
          backgroundColor: '#535353',
          callbacks: {
            label: (tooltipItem, data) => {

              return data.datasets[tooltipItem.datasetIndex].label + ' : ' + MathRound(tooltipItem.value, 10) + GET_UNIT_PERCENTAGE;
            },
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
            },
            gridLines: {
              zeroLineColor:'#FFF',
              axisColor: '#FFF',
              color: '#EAEAEA',
            },
          }],
          xAxes: [{
            ticks: {
              fontFamily: 'Poppins, Helvetica, sans-serif',
            },
            gridLines: {
              zeroLineColor:'#FFF',
              axisColor: '#FFF',
              color: '#EAEAEA',
            }
          }],
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
      }}
    />
  );
}

export const CHART_MANAGER_RANK_AVG = ({data}) => {
  return (
    <Bar
      data={{
        labels : ['count','count','count','count','count','count','count','count','count','count','count',],
        datasets: [
          {
            data: getGaussianFuc(),
            title: 'Sinus',
            borderColor: 'rgba(210,210,210,0.49)',
            backgroundColor: data.backColor,
            borderWidth: 1,
            width: 5,
            fill: false
          },
        ],
      }}
      options={{
        elements: {
          point: {
            radius: 0,
          },
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: 9
            }
          }],
          xAxes: [{
            display: false,
            categoryPercentage: 1,
            barPercentage: 1,
            stacked: true,
          }],
        },
        maintainAspectRatio: false,
        legend: false,
      }}
    />
  );
}

export const CHART_MANAGER_MISSION_DETAIL = ({data}) => {
  return (
    <Bar
      data={{
        labels : data.date,
        datasets: [
          {
            data: data.per,
            borderColor: 'rgba(210,210,210,0.49)',
            backgroundColor: '#4566bd',
            barPercentage: 0.35,
            borderWidth: 1,
            width: 5,
            fill: false
          },
        ],
      }}
      options={{
        elements: {
          point: {
            radius: 0,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: 120
            }
          }],
          xAxes: [{
            // categoryPercentage: 1,
            stacked: true,
          }],
        },
        maintainAspectRatio: false,
        hover: {
          animationDuration: 0
        },
        animation: {
          duration: 1,
          onComplete: function() {
            const chartInstance = this.chart;
            const ctx = chartInstance.ctx;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                const data = dataset.data[index];
                ctx.fillText(data + ' %', bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
      }}
    />
  );
}


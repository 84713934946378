import React from 'react';
import styled from 'styled-components';
import palette, { DftColor } from '../../lib/styles/palette';

const DefaultButton = styled.button`
  color: white;
  border: none;
  min-width: 110px;
  height: 30px;
  border-radius: 3px;
  background-color: ${props => props.background || DftColor };
  cursor: pointer;
  font-size: 14px;
  
  &.c-active {
    background-color: #ff7373;
  }
  
  &.c-none {
    background-color: #eaeaea;
    color: #333333!important;
  }
`;

const ButtonForm = styled.button`
  background-color: ${props => props.background || DftColor};
  border-radius: 3px;
  color: #FFF;
  padding: 0.45rem 0.9rem;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 6px 0 ${props => props.background || DftColor}7F;
  outline: none!important;
  border: none;
  
  &:hover {
    opacity: .75;
  }
`;

export const Button = props =>
  <DefaultButton
    background={props.background}
    onClick={props.onClick}
    className={props.className}
    type={props.type || 'button'}
  >
    {props.Text}
  </DefaultButton>;

export const MngButtonPopup = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DefaultButton {...props}/>
));

export const StyleButton = props => {
  return (
    <ButtonForm
      onClick={props.onClick}
      background={props.background}
      {...props}
    >
      {props.text}
    </ButtonForm>
  )
}

export default Button;

import React from 'react';
import styled from 'styled-components';
import { StyleButton } from '../../components/Button';

const Container = styled.div`
  .navbar {
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);

    ul {
      margin: 0;

      li {
        &:not(:last-child) {
          margin-right: 25px;
        }
        
        button {
          padding: 0.45rem 0.9rem;
        }
        
        a {
          font-size: 14px;
        }
        
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
`;

const AdminHeader = () => {
  const onLogout = async () => {
    await sessionStorage.clear();

    window.location.reload();
  }

  return (
    <Container className="top-nav">
      <div className="navbar">
        <ul>
          <li>
            <StyleButton text="로그아웃" background={'#fa5c7c'} onClick={onLogout}/>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default AdminHeader;
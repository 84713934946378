/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './view/App';
import * as serviceWorker from './utils/serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import rootReducer, {rootSaga} from "./modules";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga';
import UseHistory from "./utils/History";
import './lib/lang/i18n';
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'chartjs-plugin-style';
import Favicon from 'react-favicon';
import FitrusIcon from './lib/styles/img/favicon.ico';

//AG Grid CSS
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-app-polyfill/ie9';
import { enableES5 } from 'immer';
import { GET_MEMBER_TYPE } from './utils/UseFunc/UseStorage';
import 'firebase/auth';
import 'firebase/database';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

//Url 파라미터 제거
// history.replaceState({}, null, location.pathname);
enableES5();
//Url 로그인 이동 막기
function loadUser() {
  try {
    const user = sessionStorage.getItem('login');
    let reUrl = window.location.pathname.split('/');

    // ssl 인증
    if(reUrl[1] === '.well-known') return;

    if(user === null && reUrl[1] !== 'tablet' && reUrl[1] !== 'mobile' && reUrl[1] !== 'sns' && reUrl[1] !== 'appleSns')
      UseHistory.push('/');

    if(user !== null && reUrl[1] === 'company')
      if(GET_MEMBER_TYPE() !== 'A') {
        alert('접근할 수 없습니다.')
        UseHistory.push('/');
      }

    if(user !== null && reUrl[1] === 'mng')
      if(GET_MEMBER_TYPE() === 'U') {
        UseHistory.push('/');
      }
  } catch (e) {

  }
}

function withdrawalCheck() {
  if(sessionStorage.getItem('withdrawal')) {
    alert('This member does not exist.');
    sessionStorage.clear();
  }
}

/*window.addEventListener('popstate', function(e) {
  try {
    const user = sessionStorage.getItem('login');
    let reUrl = window.location.pathname.split('/');
    if (user === null && reUrl[1] !== 'tablet' && reUrl[1] !== 'sns')
      UseHistory.push('/');

    if (user !== null && reUrl[1] === 'company')
      if (GET_MEMBER_TYPE() !== 'A') {
        alert('접근할 수 없습니다.');
        UseHistory.push('/');
      }

    if (user !== null && reUrl[1] === 'mng')
      if (GET_MEMBER_TYPE() === 'U') {
        UseHistory.push('/');
      }
  } catch (e) {

  }
});*/

sagaMiddleware.run(rootSaga);
loadUser();
withdrawalCheck();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Favicon url={FitrusIcon} />
      <App/>
    </BrowserRouter>
  </Provider> ,
document.getElementById('root'));

serviceWorker.unregister();

import { callApi } from './index';

const Path = '/message';

export const getNoticeList = () => {
  return callApi().get(Path + '/list?page=0&size=10000')
    .then(res => {
      const data = [];

      res.data.list.forEach((value, index) => {
        data.push({
          date: value.date,
          title: value.title,
          contents: value.contents,
          type: value.type,
        });
      })

      res.data = data;
      return res;
    });
};

export const setNoticeList = ({ntcCnt, ntcDate, ntcTitle}) => {
  // 예약 시간이 현재 시간보다 전이면 즉시 전송으로 변경
  if(new Date(ntcDate) < new Date()) {
    ntcDate = new Date();
  }

  return callApi().post(Path + '/send', {
    contents : ntcCnt,
    title : ntcTitle,
    reserveLocalDate: new Date(ntcDate).getTime()
  }).then(res => {
    res.data = res.status;

    return res;
  })
}

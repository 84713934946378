import Resizer from 'react-image-file-resizer';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import {
  GET_COMPSEQ,
  GET_MEMBER_TYPE,
  GET_MEMBERSEQ_FOR_MNG,
} from './UseStorage';
import { callApi } from '../../api';
import React from 'react';
import i18n from 'i18next';

const BODYPER_VERY_LOW = 60;
const BODYPER_LOW = 90;
const BODYPER_NORMAL = 120;
const BODYPER_HIGH = 150;
const BODYPER_VERY_HIGH = 180;

export const BIZ_IP = 'http://localhost';
// export const BIZ_IP = 'http://141.223.149.91';
// export const BIZ_IP = 'https://52.166.88.123';
// export const BIZ_IP = 'https://fapi.thefitrus.com';
// export const BIZ_IP = 'http://172.25.4.5';
// export const BIZ_IP = 'http://210.104.190.229';
export const BIZ_URL = 'https://fapi.thefitrus.com';
// export const CHAT_IP = 'http://localhost:9112';
export const CHAT_IP = 'http://210.104.190.226:9112';
// export const BIZ_URL = BIZ_IP + ':8391';
export const BODY_TYPE = ['BFP', 'BFM', 'SMM', 'BMR', 'BMI', 'W', 'BWP'];

// new Date().format 데이트 포맷
// eslint-disable-next-line no-extend-native
Date.prototype.format = function (f) {
  if (!this.valueOf()) return ' ';

  let weekKorName = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  let weekKorShortName = ['일', '월', '화', '수', '목', '금', '토'];
  let weekEngName = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let weekEngShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let d = this;

  return f.replace(
    /(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi,
    function ($1) {
      switch ($1) {
        case 'yyyy':
          return d.getFullYear(); // 년 (4자리)
        case 'yy':
          return (d.getFullYear() % 1000).zf(2); // 년 (2자리)
        case 'MM':
          return (d.getMonth() + 1).zf(2); // 월 (2자리)
        case 'dd':
          return d.getDate().zf(2); // 일 (2자리)
        case 'KS':
          return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)
        case 'KL':
          return weekKorName[d.getDay()]; // 요일 (긴 한글)
        case 'ES':
          return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)
        case 'EL':
          return weekEngName[d.getDay()]; // 요일 (긴 영어)
        case 'HH':
          return d.getHours().zf(2); // 시간 (24시간 기준, 2자리)
        case 'hh':
          return (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()).zf(2); // 시간 (12시간 기준, 2자리)
        case 'mm':
          return d.getMinutes().zf(2); // 분 (2자리)
        case 'ss':
          return d.getSeconds().zf(2); // 초 (2자리)
        case 'a/p':
          return i18n.t(d.getHours() < 12 ? i18next.t('AM') : i18next.t('PM')); // 오전/오후 구분
        default:
          return $1;
      }
    },
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.clear = function () {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  this.setMilliseconds(0);
  return this;
};

// eslint-disable-next-line no-extend-native
Date.prototype.toDateFloat = function () {
  return this.clear() * 1;
};

// eslint-disable-next-line no-extend-native
String.prototype.string = function (len) {
  let s = '',
    i = 0;
  while (i++ < len) {
    s += this;
  }
  return s;
};
// eslint-disable-next-line no-extend-native
String.prototype.zf = function (len) {
  return '0'.string(len - this.length) + this;
};
// eslint-disable-next-line no-extend-native
Number.prototype.zf = function (len) {
  return this.toString().zf(len);
};

export const getDateStr = (date) => {
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).zf(2) +
    '-' +
    date.getDate()
  );
};

export const get5YearsLater = () => {
  const date = new Date();
  date.setFullYear(
    Number(date.format('yyyy')) + 5,
    date.format('MM'),
    date.format('dd'),
  );

  return Date.parse(date);
};

export const getGaussianFuc = () => [1, 2, 3, 4, 5, 7, 5, 4, 3, 2, 1];

export const getBarData = () => {
  const data = [];
  const defNum = 25;

  for (let i = 1; i <= 50; i++) {
    data.push(defNum + i * 1.5);
  }

  return data;
};

export const getUserRankBackGround = (num, backColor) => {
  let bg = [];

  for (let i = 1; i < 12; i++) {
    bg.push(num === i ? backColor : 'rgba(234,234,234,0.5)');
  }

  return bg;
};

//브라우저 언어 가져옴
export const getLanguage = () => {
  return navigator.language || navigator.userLanguage;
};

export const GenToPercentage = (data) => {
  const p =
    ((data - BODYPER_VERY_LOW) / (BODYPER_VERY_HIGH - BODYPER_VERY_LOW)) * 100;

  return Math.round(p * 10) / 10;
};

export const GetSomatoXY = (data) => {
  const first = Number.parseInt(data / 100);
  const second = Number.parseInt((data % 100) / 10);
  const third = (data % 100) % 10;

  const x = (third - first + 9) / 18;
  const y = (17 - (2 * second - (first + third))) / 27;

  return [x * 500, y * 430];
};

export function toPascalCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
    )
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

export function MathRound(value, point) {
  return point === 0 ? Math.round(value) : Math.round(value * point) / point;
}

export function CheckInfoRule(type, value) {
  let pattern;

  switch (type) {
    case 'id':
      pattern = /^[a-zA-Z0-9_\-\_\.\@\+]{6,40}$/;
      break;
    case 'pw':
      pattern =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!#$%&*+-./:;=?@＼^_`~])[A-Za-z\d!#$%&*+-./:;=?@＼^_`~]{8,20}$/;
      // pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@!%#?&\-_+=])[A-Za-z\d@!%#?&\-_+=]{8,20}$/;
      break;
    case 'email':
      pattern =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;
      break;
    case 'number':
      pattern = /^[0-9]*$/;
      break;
    default:
      break;
  }

  return pattern.test(value);
}

export function CheckLength(value, length) {
  if (value)
    return value.length > length ? value.substr(0, length) + '...' : value;
  else return '';
}

export function CheckEmail(email) {
  const exptext = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;

  return exptext.test(email);
}

export function CheckEmpty(value) {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (typeof value === 'object' && !Object.keys(value).length)
  );
}

export function CheckLankText(label) {
  if (label >= 0) {
    return label.toString().length > 2
      ? 'pl3'
      : label.toString().length > 1
      ? 'pl2'
      : 'pl1';
  } else {
    return label.toString().length > 3
      ? 'pr4'
      : label.toString().length > 2
      ? 'pr3'
      : 'pr2';
  }
}

export function RegExpUrl() {
  return new RegExp(
    '(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?',
    'gi',
  );
}

export function CheckLink(text) {
  const regUrl = new RegExp(
    '(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?',
    'gi',
  );

  return {
    __html: `<div>${text.replace(regUrl, (n) => {
      const http = n.includes('http') ? n : 'http://' + n;

      return `<a href=${http} target="_blank" style="color: #007bff">${n}</a>`;
    })}</div>`,
  };
}

export function CalculRank(myRank, type) {
  let lb = {
    txt: [], // 라벨 값을 넣을 배열 선언
    index: 0, // 사용자 바디 점수 위치,
    backColor: 'rgba(234,234,234,0.5)',
  };
  let min = myRank.memberMinScore; // 랭킹 최소값
  let avg = myRank.memberAvgScore; // 랭킹 평균값
  let max = myRank.memberMaxScore; // 랭킹 최대값
  let userScore =
    type === 'A'
      ? myRank.totalScore
      : type === 'B'
      ? myRank.bodyScore
      : myRank.stepScore; // 사용자 바디 점수
  lb.backColor =
    type === 'A'
      ? 'rgba(193,138,243,0.7)'
      : type === 'B'
      ? 'rgba(213,70,70,0.7)'
      : 'rgba(130,154,241,0.7)'; // 사용자 바디 점수

  // 데이터에 최소값 최대값과 평균간의 거리를 계산 해서 더 넓은 거리
  let a = avg - min > max - avg ? avg - min : max - avg;
  // let a = max - min;
  // a 에 값으로 2/11을 계산 하고 나온 값으로 x축
  let b = (a * 2) / 11;
  // let b = a / 11;
  // 위 기준으로 정한 최소값
  let c = avg - a;
  //첫 번째 배열에 최소값 넣기
  lb.txt.push(MathRound(c, 0));
  for (let i = 0; i < 11; i++) {
    let d = c;

    c += b;
    let e = Math.round(c + Number.EPSILON);

    if (userScore <= MathRound(c, 10) && MathRound(d, 10) < userScore) {
      lb.index = i;
    }
    // 위에서 구한 b를 가장 최소값인 c에 11번 더한다
    lb.txt.push(e);
    /*c += b;
    let e = Math.round(c + Number.EPSILON);

    if (userScore <= e && lb.txt[i] < userScore) {
      lb.index = i;
    }

    // 위에서 구한 b를 가장 최소값인 c에 11번 더한다
    lb.txt.push(e);*/
  }

  return lb;
}

export function CheckTypeForManager(url, num) {
  const symbol = num > 0 ? '&' : '?';

  if (GET_MEMBER_TYPE() === 'U') {
    return url;
  } else {
    return url + symbol + 'memberSeq=' + GET_MEMBERSEQ_FOR_MNG();
  }
}

export function CheckStressStatus(status) {
  switch (status) {
    case 'LOW':
      return i18next.t('AnalysisProgressHeader01');
    case 'MID':
      return i18next.t('Normal');
    case 'HIGH':
      return i18next.t('AnalysisProgressHeader03');
    case 'VERY HIGH':
      return i18next.t('AnalysisProgressHeader04');
    default:
      return '';
  }
}

export function getScoreWidth(score, MaxScore) {
  const WidthSize = 85;
  if (MaxScore >= WidthSize) {
    return score / (MaxScore / WidthSize);
  } else {
    const rest = WidthSize / MaxScore;

    return (score * rest) / ((MaxScore * rest) / WidthSize);
  }
}

export function ResizeImg(files) {
  Resizer.imageFileResizer(
    files,
    300,
    300,
    'JPEG',
    100,
    0,
    (uri) => {
      return uri;
    },
    'blob',
  );
}

export function ShowAlert(text) {
  Swal.fire({
    icon: 'success',
    title: text,
  });
}

export function ShowAlertError(text) {
  Swal.fire({
    icon: 'error',
    title: text,
  });
}

export const windowCloserListener = (popupWindow, windowHandler) => {
  return new Promise((resolve, reject) => {
    windowHandler.interval = setInterval(() => {
      if (!popupWindow.closed) return;
      setTimeout(() => {
        clearInterval(windowHandler.interval);
        return reject({
          code: 'popup-closed',
          message:
            'The popup has been closed by the user before finalizing the operation',
        });
      }, 100);
    }, 100);
  });
};

export const uriParams = {
  parse(paramString) {
    const params = {};
    const regex = /([^#?&=]+)=([^&]*)/g;
    let match = null;
    while ((match = regex.exec(paramString)) !== null) {
      const key = decodeURIComponent(match[1]);
      params[key] = decodeURIComponent(match[2]);
    }
    return params;
  },
  stringify(params) {
    return Object.entries(params)
      .map(([key, val]) => key + '=' + encodeURIComponent(val))
      .join('&');
  },
};

export const Login = async (id, pw, sns) => {
  let state = {
    type: '',
    error: '',
    code: 0,
  };

  const data = {
    compSeq: GET_COMPSEQ(),
    id,
    sns,
  };

  if (sns === 'O') {
    data.pw = pw;
  }

  await callApi()
    .post('/login', data)
    .then(async (res) => {
      state.type = res.data.type;

      await sessionStorage.setItem('login', JSON.stringify(res.data));
      await callApi()
        .get('/member/profile')
        .then(async (res) => {
          await sessionStorage.setItem('user', JSON.stringify(res.data));
        });

      await callApi()
        .get('/member/unit')
        .then((res) => {
          sessionStorage.setItem('unit', JSON.stringify(res.data));
        });

      // 로그인 한 유저에 가장 최근 bodySeq 값
      await callApi()
        .get('/body/list?page=0&size=1')
        .then(async (res) => {
          await sessionStorage.setItem(
            'bodySeq',
            JSON.stringify({
              page: 0,
              bodySeq: res.data.list[0] ? res.data.list[0].seq : null,
              date: new Date(res.data.list[0]?.date).format('yyyy-MM-dd HH:mm'),
            }),
          );
        });
    })
    .catch((error) => {
      try {
        const status = error.response.status;

        if (status === 401) {
          if (error.response.data.failCount > 5) {
            state.code = 403;
          } else {
            state.error = i18next.t('ErrorCheckId');
          }
        } else if (status === 403) {
          state.code = 403;
        }
      } catch (e) {}
    });
  return state;
};

export const SET_STEPS_AVG = (step) => {
  step = step / 7;
  return (
    Math.min(5000, step) * 0.014 +
    Math.min(5000, Math.max(0, step - 5000)) * 0.006 +
    Math.max(0, step - 10000) * 0.005
  );
};

export const FirstLetterUpperCase = (text) => {
  return text.replace(/\b[a-z]/, (letter) => letter.toUpperCase());
};

export const GET_BEFORE_TIME = (dte) => {
  // 1분 전일 경우 방금 전으로
  // 1시간 전일 경우 분으로
  // 12시간 전일 경우 시간으로
  // 그 외는 전부 YYYY-MM-DD 로 처리
  const now = new Date();
  const date = new Date(dte);
  const dif = now.getTime() - date.getTime();
  let time = '';

  if (dif / 1000 / 60 < 1) time = i18next.t('JustBefore');
  else if (dif / 1000 / 60 < 60)
    time = Math.floor(dif / 1000 / 60) + i18next.t('MinAgo');
  else if (dif / 1000 / 60 / 60 < 13)
    time = Math.floor(dif / 1000 / 60 / 60) + i18next.t('HourAgo');
  else time = date.format('yyyy-MM-dd');

  return time;
};

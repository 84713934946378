import {callApi} from './index';
import { BIZ_URL, CheckTypeForManager } from '../utils/UseFunc/UseFunction';
import { GET_MEMBER_TYPE, SET_UNIT_WEIGHT } from '../utils/UseFunc/UseStorage';

export const getBodyComp = () => {
  let url = '/user/getBodyComp?pageType=Dashboard';

  return callApi().get(CheckTypeForManager(url, 1))
    .then(async res => {
      const data = {
        wt : [],
        bfm : [],
        smm : [],
        date : []
      }

      //list => 배열
      await res.data.list.forEach((value, index) => {
        data.wt.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight) : data.wt[index - 1] || 0);
        data.bfm.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight - value.bfm) : data.bfm[index - 1] || 0);
        data.smm.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight - value.bfm - value.smm) : data.smm[index - 1] || 0);
        data.date.push(new Date(value.mdte).format('MM-dd'));
      })

      // API로 받은 리스트를 배열화 해서 전송
      res.data = data;

      return res;
    });
}

export const getUserInfo = data => {
  let url;
  if(GET_MEMBER_TYPE() === 'U') {
    url = CheckTypeForManager('/member/profile', 0);
  } else {
    url = '/member/profile?memberSeq=' + data;
  }
  return callApi().get(url)
    .then(res => {
      if(res.data.urlProfile !== null) {
        res.data.urlProfile = BIZ_URL + res.data.urlProfile
      }

      return res;
    });
}

export const getGoalAchieve = () => {
  const url = '/user/getGoalAchieve?pageType=Dashboard';
  return callApi().get(CheckTypeForManager(url, 1))
    .then(async res => {
      const data = {
        BfmGoalRate: [],
        SmmGoalRate: [],
        Date: []
      }

      //list => 배열
      await res.data.list.forEach((value, index) => {
        data.SmmGoalRate.push(value.smmGoalRate ? value.smmGoalRate : data.SmmGoalRate[index - 1] || 0);
        data.BfmGoalRate.push(value.bfmGoalRate ? value.bfmGoalRate : data.BfmGoalRate[index - 1] || 0);
        data.Date.push(new Date(value.date).format('MM-dd'));
      })

      // API로 받은 리스트를 배열화 해서 전송
      res.data = data;

      return res;
    });
}

export const getMissionRate = () => {
  return callApi().get(CheckTypeForManager('/mission/trends', 0));
}

export const editInfo = ({UserName, UserEmail, UserSeq, UserPhone}) => {
  const data = {
    email: UserEmail,
    name: UserName,
    memberSeq: UserSeq,
  };

  if(UserPhone !== undefined) {
    data.phone = UserPhone;
  }

  return callApi().post('/member/profile', data).then(res => {
    res.data = res.status;

    return res;
  });
}




import React from 'react';
import styled from 'styled-components';

const InputForm = styled.input`
  border-radius: 3px;
  border: 1px solid #d6d4d4;
  height: 35px;
  padding: 0 8px!important;
  font-size: 1rem;
`;

const AuthInputForm = styled.input`
  font-size: 1rem;
  border: none;
  padding: 1rem;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 1rem;
  
  ::placeholder {
      font-size: 0.8rem;
  }
`;

export const AuthInput = props => {
  return (
    <AuthInputForm
      placeholder={props.placeholder}
      onChange={props.onChange}
      name={props.name}
      value={props.value}
      type={props.type}
      readOnly={props.readonly}
    />
  );
}

const Input = props => {
  return(
    <InputForm
      readOnly={props.readOnly}
      value={props.value}
      className={props.className}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      type={props.type}
      placeholder={props.placeHolder}
      maxLength={props.max}
      pattern={props.pattern}
      min={props.min}
    />
  )
};

export default Input;
import { createAction, handleActions } from 'redux-actions';
import createSaga, { createActionTypes} from '../../utils/Request/RequestCreateSaga';
import * as BODY_API from '../../api/BodyApi';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { GET_MAX_DATE, GET_START_DATE } from '../../utils/UseFunc/UseStorage';
import { MathRound } from '../../utils/UseFunc/UseFunction';

//액션 타입정의
const ACTION_RESET_FORM = 'body/RESET_FORM';
const ACTION_CHANGE_VALUE = 'body/CHANGE_VALUE';
const [ACTION_BODY_PERCENTAGE, ACTION_BODY_PERCENTAGE_SUCCESS] = createActionTypes('body/ACTION_BODY_PERCENTAGE');
const [ACTION_SOMATOTYPE, ACTION_SOMATOTYPE_SUCCESS] = createActionTypes('body/ACTION_SOMATOTYPE');
const [ACTION_BODY_GUIDE, ACTION_BODY_GUIDE_SUCCESS] = createActionTypes('body/ACTION_BODY_GUIDE');
const [ACTION_BODY_GOAL, ACTION_BODY_GOAL_SUCCESS] = createActionTypes('body/ACTION_BODY_GOAL');
const [ACTION_GET_GOAL, ACTION_GET_GOAL_SUCCESS] = createActionTypes('body/ACTION_GET_GOAL');
const [ACTION_SET_BODY_GOAL, ACTION_SET_BODY_GOAL_SUCCESS] = createActionTypes('body/ACTION_SET_BODY_GOAL');
const [ACTION_BODY_TREND, ACTION_BODY_TREND_SUCCESS] = createActionTypes('body/ACTION_BODY_TREND');
const [ACTION_BODY_LIST, ACTION_BODY_LIST_SUCCESS] = createActionTypes('body/ACTION_BODY_LIST');

//Saga 생성
const SAGA_BODY_PERCENTAGE = createSaga(ACTION_BODY_PERCENTAGE, BODY_API.getBodyPercentage);
const SAGA_SOMATOTYPE = createSaga(ACTION_SOMATOTYPE, BODY_API.getSomatoType);
const SAGA_BODY_GUIDE = createSaga(ACTION_BODY_GUIDE, BODY_API.getBodyGuide);
const SAGA_BODY_GOAL = createSaga(ACTION_BODY_GOAL, BODY_API.getBodyGoal, true);
const SAGA_GET_GOAL = createSaga(ACTION_GET_GOAL, BODY_API.getGoal);
const SAGA_SET_BODY_GOAL = createSaga(ACTION_SET_BODY_GOAL, BODY_API.setBodyGoal);
const SAGA_BODY_TREND = createSaga(ACTION_BODY_TREND, BODY_API.getBodyTrend);
const SAGA_BODY_LIST = createSaga(ACTION_BODY_LIST, BODY_API.getBodyList);

// Data 저장 액션함수
  export const SET_RESET_FORM = createAction(ACTION_RESET_FORM,form => form);
export const SET_CHANGE_VALUE = createAction(ACTION_CHANGE_VALUE,({form, name, value}) => ({form, name, value}));
export const SET_BODY_GOAL = createAction(ACTION_SET_BODY_GOAL, form => form);
export const GET_BODY_PERCENTAGE = createAction(ACTION_BODY_PERCENTAGE, ({bodyPage, device}) => ({bodyPage, device}));
export const GET_SOMATOTYPE = createAction(ACTION_SOMATOTYPE,bodySeq => bodySeq);
export const GET_BODY_GUIDE = createAction(ACTION_BODY_GUIDE,bodySeq => bodySeq);
export const GET_BODY_GOAL = createAction(ACTION_BODY_GOAL,type => type);
export const GET_GOAL = createAction(ACTION_GET_GOAL,type => type);
export const GET_BODY_TREND = createAction(ACTION_BODY_TREND, ({data, type}) => ({data, type}));
export const GET_BODY_LIST = createAction(ACTION_BODY_LIST, (type, mSeq) => ({type, mSeq}));

export function* bodySaga() {
  yield takeLatest(ACTION_BODY_PERCENTAGE, SAGA_BODY_PERCENTAGE);
  yield takeLatest(ACTION_SOMATOTYPE, SAGA_SOMATOTYPE);
  yield takeLatest(ACTION_BODY_GUIDE, SAGA_BODY_GUIDE);
  yield takeLatest(ACTION_BODY_GOAL, SAGA_BODY_GOAL);
  yield takeLatest(ACTION_GET_GOAL, SAGA_GET_GOAL);
  yield takeLatest(ACTION_SET_BODY_GOAL, SAGA_SET_BODY_GOAL);
  yield takeLatest(ACTION_BODY_TREND, SAGA_BODY_TREND);
  yield takeLatest(ACTION_BODY_LIST, SAGA_BODY_LIST);
}

const initialState = {
  bodyPercentage: {},
  somatoType: {},
  bodyGuide: {},
  goal: {},
  setBodyGoal: {
    wgt: 0,
    bfp: 0,
  },
  trendDate: {
    sDate: GET_START_DATE(new Date()),
    eDate: Date.parse(new Date()),
    maxDate: GET_MAX_DATE(new Date()),
  },
  bodyTrend: {},
  bodyList: {},
  goalCheck: {},
  trendLegend : {
    chk1 : false,
    chk2 : true,
    chk3 : true,
    chk4 : true,
    chk5 : true,
    chk6 : true,
    chk7 : true,
    chk8 : true,
    chk9 : true,
    chk10 : true,
  },
  goalSuccess: false,
};

export default handleActions({
  [ACTION_RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_CHANGE_VALUE]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [ACTION_BODY_PERCENTAGE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bodyPercentage: data,
  }),
  [ACTION_SOMATOTYPE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    somatoType: data,
  }),
  [ACTION_BODY_GUIDE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bodyGuide: data
  }),
  [ACTION_BODY_GOAL_SUCCESS]: (state, {payload : data}) => ({
    ...state,
    goalSuccess : data
  }),
  [ACTION_GET_GOAL_SUCCESS]: (state, {payload : data}) => ({
    ...state,
    goal : data,
  }),
  [ACTION_SET_BODY_GOAL_SUCCESS]: (state, {payload : data}) => ({
    ...state,
    goalCheck : data
  }),
  [ACTION_BODY_TREND_SUCCESS]: (state, {payload : data}) => ({
    ...state,
    bodyTrend : data
  }),
  [ACTION_BODY_LIST_SUCCESS]: (state, {payload : data}) => {
    // 피트러스 네오(FN)은 플러스로 바꾼다
    return {
    ...state,
    bodyList : data.list.map(item => ({...item, device: item.device === 'FN' ? 'FT' : item.device}))
  }}
}, initialState);

import { API, callApi } from './index';
import {
  GET_BROWSER_COUNTRY_CODE,
  GET_BROWSER_LANGUAGE,
  GET_COMPSEQ,
  GET_DEFAULT_PASSWORD, GET_MEMBER_TYPE, GET_MEMBERSEQ,
} from '../utils/UseFunc/UseStorage';
import i18next from 'i18next';
import { SET_STEPS_AVG } from '../utils/UseFunc/UseFunction';

export const getMyInfo = () => {
  return callApi().get('/company/profile');
}

export const getManagerInfo = () => {
  return callApi().get('/member/profile?memberSeq=' + GET_MEMBERSEQ());
}

export const setMyInfo = ({info}) => {
  const url = GET_MEMBER_TYPE() === 'S' ? '/company/profile' : '/member/profile';
  const data = {
    countryCode: info.countryCode,
    email: info.email,
  };

  if (GET_MEMBER_TYPE() === 'S') {
    data.address = info.address;
    data.tel = info.tel;
    data.compName = info.compName;
    data.zipCode = info.zipCode;
  } else {
    data.name = info.name;
    data.phone = info.phone;
  }

  return callApi().post(url, data).then(res => {
    res.data = res.status;

    return res;
  });
};

export const setCompLogo = data => {
  const formData = new FormData();
  formData.append('img', data)

  const url = GET_MEMBER_TYPE() === 'S' ? '/company/logo' : '/member/profileImg';

  if (GET_MEMBER_TYPE() === 'M') {
    formData.append('memberSeq', GET_MEMBERSEQ());
  }

  return callApi().post(url, formData).then(res => {
    res.data = res.status;
    return res;
  });
};

export const setPromotionLogo = img => {
  let status;

  for (let i = 0; i < img.length; i++) {
    if (img[i]) {
      const formData = new FormData();
      formData.append('img', img[i]);

      status = callApi().post('/company/ads?order=' + i, formData);
    }
  }

  return status;
};

// export const setMemberLogo = form => {
//   const formData = new FormData();
//   formData.append('img', form.urlLogo);
//   formData.append('memberSeq', form.memberSeq);
//   return callApi().post('/member/profileImg', formData).then(res => {
//     res.data = res.status;
//     return res;
//   });
// };
export const resetPassword = data => {
  return callApi().post('/company/resetPassword', {
    memberSeq : data
  });
}

export const getRank = ({ range, type, mSeq }) => {
  const data = {};

  return callApi().get(`/member/rank?range=${range}&type=${type}${mSeq ? `&memberSeq=${mSeq}` : ''}`)
    .then(res => {
      data.rank = res.data;

      return callApi().get(`/member/rankList?range=${range}&type=${type}&page=0&size=100`);
    }).then(res => {
      if(type === 'act') {
        res.data.list.forEach((val, index) => {
          res.data.list[index].score = SET_STEPS_AVG(val.score);
        });
      }
      data.rankList = res.data;

      res.data = data;

      return res;
    });
};

export const getManager = () => {
  return callApi().get('/company/listManager?page=0&size=10000');
}

export const getMember = form => {
  const { mSeq, name } = form;

  const nickname = name ? (mSeq === null ? '?' : '&') + 'name=' + name : '';

  return callApi().get('/manager/memberList' + (mSeq === null ? '' : '?mngIndex=' + mSeq) + nickname)
    .then(res => {
      const data = [];
      res.data.list.forEach(value => {
        data.push({
          type: value.utype ? (value.utype === 'U' ? 'User' : 'Manager') : '',
          name: value.uname,
          id: value.uid,
          mSeq: value.uindex,
          gender: value.ugender ? (value.ugender === 'M' ? i18next.t('GenderM') : i18next.t('GenderF')) : '',
          checkIn: value.checkIn === 1,
          lastDte: value.lastMeasureDate ? new Date(value.lastMeasureDate).format('yyyy-MM-dd') : '-',
          regDte: new Date(value.insDate).format('yyyy-MM-dd'),
          mngNm: value.mngName !== null ? value.mngName : (value.utype === 'U' ? i18next.t('MngMemberUnclassified') : value.uname),
          device: value.lastMeasureDevCode
          // mngNm: value.mngName
        });
      });

      res.data = data;

      return res;
    });
}

export const getMemberProfile = mSeq => {
  const data = {};

  return callApi().get('/member/profile?memberSeq=' + mSeq)
    .then(res => {
      data.info = res.data;

      return callApi().get('/member/manager?memberSeq=' + mSeq);
    }).then(res => {
      data.manager = res.data;

      return { data };
    }).catch(error => {
      data.manager = {};

      return { data };
    });
}

export const createMember = form => {
  const url = '/company/register' + (form.RegMType === 'U' ? 'User' : 'Manager');

  const data = {
    login: {
      compSeq: GET_COMPSEQ(),
      id: form.RegId,
      lang: GET_BROWSER_LANGUAGE,
      pw: GET_DEFAULT_PASSWORD,
      sns: 'O',
    },
    profile: {
      countryCode: GET_BROWSER_COUNTRY_CODE,
      email: form.RegEmail,
      gender: form.RegGender,
      name: form.RegName,
      phone: form.RegTel,
    },
  };

  if (form.RegMType === 'U') {
    data.managerSeq = form.RegManager;
  }

  return callApi().post(url, data)
    .then(res => {
      /*if(form.RegMType === 'U' && form.RegManager) {
        return API().post('/insertRoomForMember', {
          uIndex: res.data.profile.memberSeq,
          mngIndex: Number(form.RegManager)
        }).then(res => {
          return res;
        });
      }*/

      return res;
    });
}

export const editMember = form => {
  const { RegImgUrl, RegGender, RegManager, RegManagerOld, RegEmail, RegName, RegTel, mSeq } = form;

  return callApi().post('/member/profile', {
    memberSeq: mSeq,
    gender: RegGender,
    email: RegEmail,
    name: RegName,
    phone: RegTel,
  }).then(res => {
    if (RegImgUrl && RegImgUrl.type === 'image/jpeg') {
      const formData = new FormData();
      formData.append('img', RegImgUrl);
      formData.append('memberSeq', mSeq);

      return callApi().post('/member/profileImg', formData);
    } else {
      return res;
    }
  }).then(res => {
    if (Number(RegManager) !== Number(RegManagerOld)) {
      return callApi().post('/company/setManager', {
        list: [mSeq],
        managerSeq: RegManager
      }).then(manager => {
        /*if(manager.status === 200) {
          return API().post('/moveRoom', {
            uIndex: mSeq,
            mngIndex: Number(RegManagerOld),
            newMngIndex: Number(RegManager)
          }).then(res => {
            return res;
          });
        }*/

        return manager;
      });
    } else {
      return res;
    }
  });
}

export const deleteMember = mSeq => {
  return callApi().delete('/company/deleteMember', {
    data: {
      memberSeq : mSeq
    }
  });
}

export const changePassword = form => {
  return callApi().post('/member/changePassword', {
    memberSeq : form.mSeq,
    newPw : form.newPw,
    oldPw : form.oldPw
  }).then(res => {
    res.data = res.status;
    return res;
  })
}

export const getUnit = () => {
  return callApi().get('/member/unit');
}

export const changeUnit = form => {
  sessionStorage.setItem('unit', JSON.stringify(form));
  const { calorie, distance, height, temp, weight } = form;

  return callApi().post('/member/unit', {
    calorie,
    distance,
    height,
    temp,
    weight,
  });
};

export const checkId = id => {
  return callApi().get('/member/check?compSeq=' + GET_COMPSEQ() + '&id=' + id + '&sns=O');
}

export const withdrawalCheck = mSeq => {
  return callApi().get('/member/withdrawalCheck?memberSeq=' + mSeq);
}

export const checkPassword = form => {
  return callApi().post('/member/checkPassword', {
    memberSeq: form.mSeq,
    pw: form.pw,
  });
}

/**
 * @typedef {Object} GetMemberNewPaylod
 * @property {Number} page
 * @property {Number} rowsPerPage
 * @property {'nickname' | 'date'} [sort]
 * @property {'desc' | 'asc'} [desc]
 * @property {String} [name]
 * @property {Number} mngIndex
 * @property {Boolean} all
 */
/**
 * @param {GetMemberNewPaylod} form
 * @returns { import('axios').AxiosResponse<any> | Error }
 */
// eslint-disable-next-line arrow-parens
export const getMemberNew = async (form) => {
  try {
    const { page, rowsPerPage, sort, desc, name, mngIndex, all } = form;

    // if (!mngIndex) throw new Error('`mngIndex` is required.')
    if (!page) throw new Error('`page` is required.')
    if (!rowsPerPage) throw new Error('`rowsPerPage` is required.')

    console.log(form)

    let res

    if (all) {
      res = await callApi()
        .post(
          '/manager/memberListAll',
          {
            page,
            rowsPerPage,
            sort: sort ?? null,
            desc: desc ?? null,
            name: name || '',
          }
        )
    } else {
      res = await callApi()
        .post(
          '/manager/memberListNew',
          {
            page,
            rowsPerPage,
            sort: sort ?? null,
            desc: desc ?? null,
            name: name || '',
            mngIndex: mngIndex ?? null,
          }
        )
    }

    // eslint-disable-next-line arrow-parens
    const data = res.data.data.map((value) => ({
      type: value.utype ? (value.utype === 'U' ? 'User' : 'Manager') : '',
      name: value.uname,
      id: value.uid,
      mSeq: value.uindex,
      gender: value.ugender ? (value.ugender === 'M' ? i18next.t('GenderM') : i18next.t('GenderF')) : '',
      checkIn: value.checkIn === 1,
      lastDte: value.lastMeasureDate ? new Date(value.lastMeasureDate).format('yyyy-MM-dd') : '-',
      regDte: new Date(value.insDate).format('yyyy-MM-dd'),
      mngNm: value.mngName !== null ? value.mngName : (value.utype === 'U' ? i18next.t('MngMemberUnclassified') : value.uname),
      device: value.lastMeasureDevCode
      // mngNm: value.mngName
    }))

    return { data: { res, data, total: res.data.total } }
  } catch (e) {
    console.error(e)
    return e
  }
}

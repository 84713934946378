import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { SOCKET } from '../../utils/UseFunc/socket';
import { CheckEmpty } from '../../utils/UseFunc/UseFunction';
import { GET_MEMBERSEQ } from '../../utils/UseFunc/UseStorage';

export const ROOM_IN = 'ROOM_IN';
export const GET_LIST2 = 'GET_LIST';
export const CHATTING_LIST = 'CHATTING_LIST';
export const CHATTING = 'CHATTING';
export const MESSAGE_LIST = 'MESSAGE_LIST';
export const MESSAGE = 'MESSAGE';
export const RESET_FORM = 'RESET_FORM';

export const SET_ROOM_IN = createAction(ROOM_IN, form => form);
export const SET_MESSAGE_LIST = createAction(MESSAGE_LIST, list => list);
export const SET_MESSAGE = createAction(MESSAGE, form => form);
export const GET_CHATTING_LIST = createAction(CHATTING_LIST, form => form);
export const SET_CHATTING_LIST = createAction(CHATTING, form => form);
export const GET_LIST = createAction(GET_LIST2, form => form);
export const SET_RESET_FORM = createAction(RESET_FORM, form => form);

const initialState = {
  room: null,
  list: [],
  msg: ''
};

const socket = SOCKET;

export default handleActions({
  [RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ROOM_IN]: (state, { payload: { uIndex } }) => {
    // 채팅방을 이동하는 경우 이전 채팅방 나가기
    // if(!CheckEmpty(prevRoom))
    //   socket.emit('roomOut', { room: prevRoom.cr_index });

    // 새로운 채팅방 접속
    socket.emit('roomIn', { uIndex });

    return produce(state, draft => {
      draft.room = uIndex;
    });
  },
  /*[MESSAGE]: (state, { payload: { msg, reply } }) => {
    socket.emit('message', { uIndex: GET_MEMBERSEQ(), msg, reply: reply.status ? reply.index : null });
  },*/
  [MESSAGE_LIST]: (state, { payload: { list } }) =>
    produce(state, draft => {
      draft.list = draft.list.concat(list);
    }),
  [CHATTING]: (state, { payload: { info } }) => ({
    ...state,
    list: state.list.concat([info]),
  }),
  /*{
    console.log(info, state);
    return produce(state, draft => {
      draft.list = draft.list.concat([info]);
    });
  },*/
  [CHATTING_LIST]: (state, { payload: { data, concat } }) => {
    return produce(state, draft => {
      // draft.list = data;
      draft.list = concat ? draft.list.concat(data) : data;
    });
  },
  [GET_LIST2]: (state, { payload: { page } }) => {
    return produce(state, draft => {
      draft.room = page;
    });
  },
}, initialState);

import React from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import Modal from 'react-bootstrap/Modal';
import { DftColor } from '../../../lib/styles/palette';

const ModalHeader = styled.div`
  padding: 1rem;
  font-size: 1rem;
  background: ${props => props.background || 'linear-gradient(45deg, #339bb3, '+DftColor+')'};
  color: #FFF;
  font-weight: 400;
  
  span {
    float: right;
    cursor: pointer;
    line-height: 20px;
  }
  
  svg {
    width: 20px;
    height: 20px; 
    
    path {
      stroke: #FFF;
    }
  }
`;

const ModalForm = props => {
  return (
    <Modal
      {...props}
      backdrop="on"
      centered
    >
      <ModalHeader background={props.background}>
        <div>
          {props.header}

          <span onClick={props.onHide}>
            <GrClose/>
          </span>
        </div>
      </ModalHeader>

      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default ModalForm;
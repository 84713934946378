import { callApi } from './index';
import {
  GET_BROWSER_COUNTRY_CODE,
  GET_BROWSER_LANGUAGE,
  GET_DEFAULT_PASSWORD, GET_TIMEZONE,
} from '../utils/UseFunc/UseStorage';
import i18next from 'i18next';

//로그인
export const Login = ({ CompSeq, AuthId, AuthPw }) =>
  callApi().post('/login', {
    compSeq: CompSeq,
    id: AuthId,
    sns: 'O',
    pw: AuthPw,
    timezone: GET_TIMEZONE,
    lang: GET_BROWSER_LANGUAGE,
  });


export const findPw = ({ CompSeq, FindId, FindEmail }) => {
  return callApi().post('/member/findPassword', {
    compSeq: CompSeq,
    id: FindId,
    email: FindEmail,
    lang: GET_BROWSER_LANGUAGE,
  }).then(res => {
    res.data = res.status;

    return res;
  }).catch(error => {
    try {
      if (error.response.status === 404 || error.response.status === 409) {
        alert(i18next.t('ErrorCheckEmail'));
      }
    } catch (e) {

    }
  });
};

export const setCompany = form => {
  const expDte = form.CompExpDte > 0 ? form.CompExpDte : null;
  if (form.TypeCheck) {
    return callApi().post('/company/profile', {
      address: form.CompAdr,
      compName: form.CompName,
      compSeq: form.CompSeq,
      email: form.CompEmail,
      tel: form.CompTel,
      expDte: expDte,
    }).then(res => {
      res.data = res.status;

      return res;
    });
  } else {
    return callApi().post('/company/register', {
      companyInfo: {
        cgbn: 'O',
        cstatus: 'Y',
        ctype: 'F',
        expDte: expDte,
        pcode: form.CompCode,
        ppw: GET_DEFAULT_PASSWORD,
        caddr: form.CompAdr,
        ctel: form.CompTel,
      },
      companyProfile: {
        address: form.CompAdr,
        compName: form.CompName,
        countryCode: GET_BROWSER_COUNTRY_CODE,
        email: form.CompEmail,
        tel: form.CompTel,
      },
      login: {
        lang: GET_BROWSER_LANGUAGE,
        pw: GET_DEFAULT_PASSWORD,
        id: form.CompId,
        sns: 'O',
      },
      profile: {
        email: form.CompEmail,
      },
      pw: 'one1softdigm!',
    }).then(res => {
      res.data = res.status;

      return res;
    });
  }
};

export const getCompany = ({ type, codeName }) => {
  const status = type.length > 0 ? 'cStatus=' + type : '';
  const compInfo = codeName.length > 0 ? '&compInfo=' + codeName : '';
  return callApi().get('/company/companyList?' + status + compInfo)
    .then(res => {
      const data = [];

      res.data.list.forEach(v => {
        data.push({
          id: v.id,
          compName: v.compName,
          compCode: v.pcode,
          email: v.email,
          regDate: new Date(v.regDate).format('yyyy-MM-dd'),
          expDte: new Date(v.expDte).format('yyyy-MM-dd'),
          index: v.cindex,
          status: v.cstatus === 'Y' ? '사용' : '미사용',
          addr: v.caddr,
          tel: v.ctel,
          mSeq: v.mindex,
          cntUser: v.cntUser
        });
      });

      res.data = data;

      return res;
    });
};

export const deleteCompany = data => {
  return callApi().delete('/company/deleteCompany', {
    data: {
      list: [
        data,
      ],
    },
  }).then(res => {
    res.data = res.status;

    return res;
  });
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { callApi } from '../../api';
import i18n from 'i18next';
import { resources } from '../../lib/lang/i18n';

const MobileController = ({history, match}) => {
  useEffect(() => {
    const { params } = match;

    sessionStorage.setItem('login', JSON.stringify({
      token: params.token,
      lang: params.lang || 'EN',
      profile: {
        memberSeq: Number(params.mSeq)
      }
    }));

    i18n.init({
      // lng: GET_LANGUAGE_I18N(),
      lng: params.lang === 'KO' ? 'KO' : 'EN',
      debug: true,
      resources
    });

    callApi().get(`/member/profile?memberSeq=${params.mSeq}`)
      .then(async res => {
        await sessionStorage.setItem('user', JSON.stringify(res.data));
      });

    sessionStorage.setItem('mobile', true);
    sessionStorage.setItem('height', params.height);

    history.push('/usr/chat');
  }, [history, match]);

  return (
    <>
    </>
  );
};

export default MobileController;

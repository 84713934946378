import i18n from 'i18next';
import translationEn from './en/translation.en';
import translationKR from './kr/translation.kr';
import { GET_LANGUAGE_I18N } from '../../utils/UseFunc/UseStorage';

export const resources = {
  EN: {
    translation: translationEn
  },
  KR: {
    translation: translationKR
  }
};

i18n.init({
  lng: GET_LANGUAGE_I18N(),
  // lng: 'EN',
  debug: false,
  resources
});

/*
언어 변경

i18next.init({
  lng : 'en'
})*/

export default i18n;
import { GET_MAX_DATE, GET_START_DATE } from '../utils/UseFunc/UseStorage';

const dateModule = {
  sDate : GET_START_DATE(new Date()),
  eDate : Date.parse(new Date()),
  maxDate : GET_MAX_DATE(new Date()),
  type : 'Detail'
}

export const initialState = {
  alertConfig: {
    header: '',
    text: '',
    type: '',
    check: false,
    submit: null,
    visible: false,
  },
  myInfo: {},
  changePw: {
    oldPw: '',
    newPw: '',
    newPwRe: '',
  },
  myRank: {},
  regMember: {
    RegId: '',
    RegName: '',
    RegEmail: '',
    RegGender: 'M',
    RegMType: 'U',
    RegManager: '',
    RegMangerOld: '',
    RegTel: '',
    RegImgUrl: null,
    type: 'A',
    mSeq: 0
  },
  cptLogo: {
    urlLogo: null,
  },
  pmtLogoForm: {
    pmtLogo0: null,
    pmtLogo1: null,
    pmtLogo2: null,
    pmtLogo3: null,
    pmtLogo4: null,
  },
  noticeInfo: {
    ntcTitle: '',
    ntcCnt: '',
    ntcDate: new Date().format('yyyy-MM-ddTHH:mm'),
  },
  notice: {},
  manager: {},
  member: {},
  memberTotal: 0,
  mission: {},
  missionNow: {},
  missionDownloadList: {},
  missionComment: {
    contents: '',
    parents: null,
    img: [],
  },
  missionReComment: {
    contents: '',
    parents: null,
    img: [],
  },
  msnTopic: {
    topic: ''
  },
  msnNote: {
    note: '',
  },
  msnNoteList: [],
  msnTopicList: {},
  msnOp: {
    strDte: new Date(),
    endDte: new Date(),
    dteChg: false,
    allChk: false,
    noteSeq: 0
  },
  setMission: {
    msnTitle: '',
    msnCnt: '',
    msnDate: new Date().format('yyyy-MM-ddTHH:mm'),
    msnCtg: [],
    msnRpt: [],
    urlLogo: '',
  },
  rptList: [],
  rptAchieveList: [],
  setNotes: [],
  totalUserGender: {},
  totalAge: {},
  totalWeight: {},
  scatterPlot: {},
  bodyComp: {},
  msnCmtList: [],
  bodyCompDte: dateModule,
  scatterPlotDte: dateModule,
  goalAchieveDte: dateModule,
  missionDte: dateModule,
  missionRate: {},
  missionPer: {},
  missionMemList: {},
  missionMemAnalysis: {},
  mBodyComp: {},
  mScatterPlot: {},
  mGoalAchieve: {},
  goalAchieve: {},
  memberUnit: {},
  memberUnitSuccess: false,
  msnTopicSuccess: false,
  msnNoteSuccess: false,
  msnNoteDltSuccess: false,
  msnSuccess: false,
  msnCmtSuccess: false,
  msnCmtDelSuccess: false,
  noticeSuccess: false,
  infoSuccess: false,
  editSuccess: false,
  logoSuccess: false,
  promoationLogoSuccess: false,
  memberImgSuccess: false,
  rankSuccess: false,
  regMemberSuccess: false,
  resetPwSuccess: false,
  deleteSuccess: false,
  deleteForManagerSuccess: false,
  changePwSuccess: false,
  changePwError: null,
  checkIdSuccess: false,
  checkWithdrawal: false,
  checkPasswordSuccess: false,

  //Modules 개편 이후
  mbEdtCode: null,
}

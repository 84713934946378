import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Find, Login } from '../controller/auth/Auth.async';
import { MngPg01, MngPg02, MngPg03, MngPg04, MngPg05, MngPg06, MngPg07 } from '../controller/manager/Manager.async';
import { UserPg01, UserPg02, UserPg03, UserPg04, UserPg05, UserPg06 } from '../controller/user/User.async';
import SnsLogin from './auth/AuthSnsLogin';
import AppleSnsLogin from './auth/AuthAppleSnsLogin';
import Tablet from '../controller/tablet/TabletController';
import Mobile from '../controller/mobile/MobileController';
import Company from '../controller/admin/AdminController';
import CompanyDetail from '../controller/admin/AdminDetailController';
import CompanySetting from '../controller/admin/AdminSettingController';
import '../lib/styles/css/App.css';
import '../lib/styles/css/checkbox.css';
import '../lib/styles/css/index.scss';
import '../lib/styles/css/Switch.scss';
import '../utils/UseFunc/UseFunction';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Loading from './components/Loading';
import Alert from './components/Alert';

const App = () => {
  const { loading } = useSelector(({ loading }) => ({
    loading: loading.loading,
  }));
  const history = useHistory()


  const needLogin = window.location.href.includes('mng')
    || window.location.href.includes('admin')
    || window.location.href.includes('usr')
  const loginData = sessionStorage.getItem('login')
  const token = JSON.parse(loginData)?.token

  if (needLogin && !token) {
    history.push('/')
  }


  return (
    <>
      <Loading visible={loading}>
        <Spinner animation="border" role="status" style={{width: '4rem', height: '4rem'}} />
      </Loading>

      <Alert />
      <Route exact={true} path="/" component={Login}/>

      <Route component={Login} path="/login"/>
      <Route component={SnsLogin} path="/sns"/>
      <Route component={AppleSnsLogin} path="/appleSns"/>
      <Route component={Tablet} path={['/tablet/:page/:type/:token/:mSeq/:lang/:username']}/>
      <Route component={Mobile} path={['/mobile/:token/:mSeq/:lang/:height']}/>
      <Route component={Find} path="/find"/>
      <Route component={Company} path="/admin/list" exact={true} />
      <Route component={CompanyDetail} path="/admin/list/:index" exact={true} />
      <Route component={CompanySetting} path="/admin/setting" exact={true} />

      <Route component={MngPg01} path="/mng/dashboard"/>
      <Route component={MngPg02} path="/mng/rank"/>
      <Route component={MngPg03} path="/mng/member"/>
      <Route component={MngPg04} path={['/mng/noti/msn/ctg', '/mng/noti/msn/list', '/mng/noti/notice']} />
      {/*<Route component={MngPg05} path={['/mng/chat']} />*/}
      <Route component={MngPg06} path={['/mng/setting/accInfo', '/mng/setting/chgPw', '/mng/setting/cptLogo', '/mng/setting/pmtLogo', '/mng/setting/unitSet']} />
      <Route component={MngPg07} path={['/usr/exercise']} />

      <Route component={UserPg01} path="/usr/info" exact={true}/>
      <Route component={UserPg02} path="/usr/body" exact={true}/>
      <Route component={UserPg03} path="/usr/rank" exact={true}/>
      <Route component={UserPg05} path="/usr/mission" exact={true}/>
      {/*<Route component={UserPg06} path="/usr/chat" exact={true}/>*/}
      <Route component={UserPg04} path="/usr/health" exact={true}/>
    </>
  );
};

export default App;

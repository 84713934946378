import React from 'react';
import styled from 'styled-components';
import AdminMenu from './AdminMenu';
import AdminHeader from './AdminHeader';

const MainNav = styled.div`
  min-width: 275px;
  height: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
`;

const MainBody = styled.div`
  padding: 2rem;
  
  &::before {
    background-size: 100% 200%;
    width: 100%;
    background-repeat: no-repeat;
    content: "";
    position: absolute; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

const AdminTemplate = props => {
  return (
    <div className="d-flex">
      <MainNav>
        <AdminMenu />
      </MainNav>

      <MainContainer>
        <AdminHeader />

        <MainBody>
          {props.children}
        </MainBody>
      </MainContainer>
    </div>
  );
};

export default AdminTemplate;

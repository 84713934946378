import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import Input from '../Input';
import Button from '../Button';
import ModalFrame from './ModalFrame';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserField, editInfo, resetForm } from '../../../modules/user/UserModules';
import { CheckInfoRule } from '../../../utils/UseFunc/UseFunction';
import { Error } from '../Error';
import { SET_ALERT } from '../../../modules/common/AlertModule';

const ModalCrudContents = styled.div`
  display: flex;
`;

const ModalCrudContentsLabel = styled.div`
  ul {
    padding-left: 15px;
    margin: 0!important;
    
    li {
      padding-top: 5px;
      height: 45px;
    }
  }
  
  .Dtl {
    display: ${props => props.type !== 'Detail' && 'none'};
  }
  
  .Crud {
    display: ${props => props.type === 'Detail' && 'none'};
  }
`;

const ModalCrudContentsInput = styled.div`
  width: 75%;
  
  ul {
    padding-left: 25px;
    margin: 0!important;
    
    input, Select {
      width: 100%;
      border: 0;
      border-bottom: 1px solid #9a9999;
      border-radius: 0;
      font-size: 1rem;
    }
    
    textarea {
      resize: none;
      width: 100%;
      height: 80px;
      padding: 5px;
      font-family: 'Noto Sans', Arial, sans-serif!important;
      font-size: 1rem;
    }
    
    li {
      height: 45px;
    }
  }
  
  .Dtl {
    display: ${props => props.type !== 'Detail' && 'none'};
  }
  
  .Crud {
    display: ${props => props.type === 'Detail' && 'none'};
  }
`;

const ModalCrudFooter = styled.div`
  text-align: center;
  padding-bottom: 20px;
  
  button:first-child {
    margin-right: 60px;
  }
`;

const ModalUsrInfoCrud = props => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { form, editSuccess } = useSelector(({ user }) => ({
    form: user.userEdit,
    editSuccess: user.editSuccess
  }));

  const onChange = e => {
    const { value, name } = e.target;

    dispatch(
      changeUserField({
        form: 'userEdit',
        key: name,
        value,
      }),
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    const {UserName, UserEmail, UserSeq} = form;
    
    if([UserName].includes('')) {
      setError(i18next.t('ErrorBlank'));
      
      return;
    }

    if (!CheckInfoRule('email', UserEmail)) {
      setError(i18next.t('SuccessEmail'));
      
      return;
    }

    dispatch(
      editInfo({
        UserEmail,
        UserName,
        UserSeq,
      }),
    );
  };
  
  useEffect(() => {
    if(editSuccess) {
      dispatch(SET_ALERT({ type: 'Success', text: i18next.t('SuccessEdit'), reload: true }));
      dispatch(resetForm('editSuccess'));
      props.CloseModal();
    }
  }, [dispatch, editSuccess])

  return (
    <ModalFrame HeaderText={props.HeaderText}>
      <form action="" onSubmit={onSubmit} className="p-3">
        <ModalCrudContents>
          <ModalCrudContentsLabel type={props.HeaderText}>
            <ul>
              <li>{i18next.t('MyInfo01')}</li>
              <li>{i18next.t('AccountPhd02')}</li>
              <li>{i18next.t('MyInfo02')}</li>
            </ul>
          </ModalCrudContentsLabel>

          <ModalCrudContentsInput type={props.HeaderText}>
            <ul>
              <li><Input name="UserName" value={form.UserName} onChange={e => onChange(e)} max={50}/></li>
              <li><Input name="UserId" readOnly={true} value={form.UserId} onChange={e => onChange(e)}/></li>
              <li><Input name="UserEmail" value={form.UserEmail} onChange={e => onChange(e)} max={40}/></li>
            </ul>
          </ModalCrudContentsInput>
        </ModalCrudContents>

        <div className="m-3 text-center">
          {error && <Error Text={error}/>}
        </div>

        <ModalCrudFooter>
          <Button Text={i18next.t('ButtonCancel')} background="#8e8e8e" onClick={props.CloseModal} />
          <Button Text={i18next.t('ButtonSave')} type="submit"/>
        </ModalCrudFooter>
      </form>
    </ModalFrame>
  );
};

export default ModalUsrInfoCrud;
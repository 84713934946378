import React, { useEffect } from 'react';
import { callApi } from '../../api';

const TabletController = ({history, match}) => {
  useEffect(() => {
    const { params } = match;

    sessionStorage.setItem('login', JSON.stringify({
      token: params.token,
      lang: params.lang || 'EN',
      type: params.type,
      profile: {
        memberSeq: params.mSeq
      }
    }));

    if (params.type !== 'U') {
      if (params.username) {
        sessionStorage.setItem('userInfo', {
          name: params.username,
        });
      }
    }

    callApi().get(`/member/profile?memberSeq=${params.mSeq}`)
      .then(async res => {
        await sessionStorage.setItem('user', JSON.stringify(res.data));
      });

    callApi().get('/member/unit')
      .then(res => {
        sessionStorage.setItem('unit', JSON.stringify(res.data));
      });

    sessionStorage.setItem('tablet', true);
    sessionStorage.setItem('managerForMseq', params.mSeq);

    if (params.type === 'U') {
      history.push('/usr/rank');
    } else {
      if (params.page === 'rank') {
        history.push('/mng/rank');
      } else {
        history.push('/mng/dashboard');
      }
    }
  }, [history, match]);

  return (
    <>
    </>
  );
};

export default TabletController;
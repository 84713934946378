import React from 'react';
import styled from 'styled-components';

const TableForm = styled.table`
  width: 100%;
  
  tr {
    height: 50px;
    font-size: 14px;

    th {
      background: #f5f5f5;
      border-color: #ccc;
      border-style: solid;
      border-width: 0 1px 1px 0;
      padding: 6px 10px;
      width: 30%;
    }
    
    td {
      border-bottom: 1px solid #ccc;
      padding: 6px 10px;
    }
    
    input:not(#profileImg) {
      border: 1px solid #ccc;
      width: 100%;
      padding: 6px;
      color: #000;
      font-size: 14px;
    }
    
    img {
      min-width: 80px;
      height: 80px;
      border: 1px solid #ccc;
    }
    
    label:not(.chkBox) {
      padding: 7px 1rem;
      font-weight: 400;
      margin: 0 0 0 5px;
      background: #abacae;
      color: #FFF;
      cursor: pointer;
    }
    
    .chkBox {
      margin: 0;
    }
    
    textarea {
      border: 1px solid #ccc!important;
      height: 250px;
      padding: 1rem;
    }
  }
`;

const Table = props => {
  return (
    <TableForm>
      {props.children}
    </TableForm>
  )
};

export default Table;
import { callApi } from './index';
import {
  CheckEmpty,
  CheckStressStatus,
  CheckTypeForManager,
  MathRound,
} from '../utils/UseFunc/UseFunction';
import {
  GET_MEMBER_TYPE,
  GET_MEMBERSEQ,
  GET_MEMBERSEQ_FOR_MNG,
  GET_UNIT_PERCENTAGE,
  GET_UNIT_TEMP,
  SET_UNIT_TEMP,
} from '../utils/UseFunc/UseStorage';
import i18next from 'i18next';

export const getHealth = (data) => {
  const url =
    '/user/getBpmIndicator?startDate=' + data.sDate + '&endDate=' + data.eDate;
  return callApi()
    .get(CheckTypeForManager(url, 2))
    .then((res) => {
      if (!CheckEmpty(res.data.list)) {
        const data = {
          date: [],
          bpm: [],
          oxygen: [],
          list: [],
        };

        res.data.list.forEach((value) => {
          data.date.push(new Date(value.date).format('MM-dd'));
          data.bpm.push(value.bpm);
          data.oxygen.push(value.oxygen);
          data.list.push({
            Bpm : value.bpm,
            Oxygen : value.oxygen + GET_UNIT_PERCENTAGE,
            Date : new Date(value.date).format('yyyy-MM-ddㅤHH:mm')
          })

        });

        data.date.reverse();
        data.bpm.reverse();
        data.oxygen.reverse();

        if (data.date.length < 10) {
          const arrayDate = new Array(10 - data.date.length).fill('');
          const arrayValue = new Array(10 - data.date.length).fill(null);

          data.date = data.date.concat(arrayDate);
          data.bpm = data.bpm.concat(arrayValue);
          data.oxygen = data.oxygen.concat(arrayValue);
        }

        res.data = data;
      }

      return res;
    });
};

export const getBpmBand = (page) => {
  return callApi().get(
    `/v19heart/listExistDataAllDev?page=${page}&memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

export const getRun = (data) => {
  return callApi().get(
    `/run/list?startDay=${data.sDate}&endDay=${data.eDate}&memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

export const getSleep = (page) => {
  return callApi().get(
    `/v19sleep/listExistData?page=${page}&memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

export const getSleepPeriod = (data) => {
  return callApi().get(
    `/v19sleep/sleepDetailDte?endDate=${data.eDate}&startDate=${
      data.sDate
    }&memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

export const getBloodPressure = (page) => {
  const data = {
    list: {},
    personal: {},
  };
  return callApi()
    .get(
      `/v19blood/listExistData?page=${page}&memberSeq=${
        GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
      }`,
    )
    .then((res) => {
      data.list = res.data;
      return callApi().get(
        `/v19blood/personalBpPattern?memberSeq=${
          GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
        }`,
      );
    })
    .then((res) => {
      data.personal = res.data;
      res.data = data;

      return res;
    });
};

export const get_Temp_BloodPressureDirectInput = (memberSeq) => {
  return callApi().get(`/v19blood/getBpManually?memberSeq=${memberSeq}`)
}

export const get_BloodPressure_Input = (data) => {
  const url =
    '/user/getBloodPressure?startDate=' +
    new Date(data.sDate).format('yyyy-MM-dd') +
    '&endDate=' +
    new Date(data.eDate).format('yyyy-MM-dd')
  const mSeq = data.memberSeq ? '&memberSeq=' + data.memberSeq : ''
    
  return callApi().get(url + mSeq)
}

export const get_BloodSugar_Input = (data) => {
  const url =
    '/user/getBloodSugar?startDate=' +
    new Date(data.sDate).format('yyyy-MM-dd') +
    '&endDate=' +
    new Date(data.eDate).format('yyyy-MM-dd')
  const mSeq = data.memberSeq ? '&memberSeq=' + data.memberSeq : ''
  return callApi().get(url + mSeq)
}

export const getStress = (data) => {
  const url =
    '/user/getStressIndicator?startDate=' +
    data.sDate +
    '&endDate=' +
    data.eDate;

  return callApi()
    .get(CheckTypeForManager(url, 1))
    .then((res) => {
      const data = {
        date: [],
        stressStatus: [],
        stressIndex: [],
        stress: [],
      };

      res.data.list.forEach((value) => {
        data.date.push(new Date(value.date).format('MM-dd'));
        data.stressStatus.push(value.stressStatus);
        data.stressIndex.push(value.stressIndex);
        data.stress.push({
          Date: new Date(value.date).format('yyyy-MM-ddㅤHH:mm'),

          StressStatus: CheckStressStatus(value.stressStatus),
        });
      });

      data.date.reverse();
      data.stressIndex.reverse();

      if (data.date.length < 10) {
        const arrayDate = new Array(10 - data.date.length).fill('');
        const arrayValue = new Array(10 - data.date.length).fill(null);

        data.date = data.date.concat(arrayDate);
        data.stressStatus = data.stressStatus.concat(arrayValue);
        data.stressIndex = data.stressIndex.concat(arrayValue);
      }

      res.data = data;

      return res;
    });
};

export const getStressBand = (page) => {
  return callApi().get(
    `/v19Stress/listExistData?page=${page}&memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

export const getSteps = (data) => {
  const url =
    '/user/getStepIndicator?startDate=' + data.sDate + '&endDate=' + data.eDate;

  return callApi()
    .get(CheckTypeForManager(url, 1))
    .then((res) => {
      if (res.data.list.length > 0) {
        const data = {
          date: [],
          step: [],
          tdSteps: MathRound(res.data.todaySteps, 10),
          tdDistance: MathRound(res.data.todayDistance / 1000, 10),
          tdCalories: MathRound(res.data.todayCalories, 10),
          wkDistance: MathRound(res.data.weekDistance / 1000, 10),
          wkCalories: MathRound(res.data.weekCalories, 10),
        };

        res.data.list.forEach((value, index) => {
          data.date.push(new Date(value.date).format('MM-dd'));
          data.step.push(value.step);
        });

        if (data.date.length < 10) {
          const arrayDate = new Array(10 - data.date.length).fill('');
          const arrayValue = new Array(10 - data.date.length).fill(null);

          data.date = arrayDate.concat(data.date);
          data.step = arrayValue.concat(data.step);
        }

        res.data = data;
      }

      return res;
    });
};

export const getTemperature = (data) => {
  const url =
    '/user/getTemperatureIndicator?startDate=' +
    data.sDate +
    '&endDate=' +
    data.eDate;

  return callApi()
    .get(CheckTypeForManager(url, 1))
    .then((res) => {
      const data = {
        date: [],
        temperature: [],
        tempList: [],
      };

      res.data.skinTemperatureList.list.forEach((value, index) => {
        data.date.push(new Date(value.date).format('MM-dd'));
        data.temperature.push(SET_UNIT_TEMP(value.temperature));
      });

      res.data.temperatureList.list.forEach((value) => {
        data.tempList.push({
          Date: new Date(value.date).format('yyyy-MM-dd HH:mm'),
          Category:
            value.category === 'S'
              ? i18next.t('TempHeader01')
              : i18next.t('TempHeader02'),
          Temperature: SET_UNIT_TEMP(value.temperature) + GET_UNIT_TEMP(),
        });
      });

      if (data.date.length < 10) {
        const arrayDate = new Array(10 - data.date.length).fill('');
        const arrayValue = new Array(10 - data.date.length).fill(null);

        data.date = data.date.concat(arrayDate);
        data.temperature = data.temperature.concat(arrayValue);
      }

      res.data = data;

      return res;
    });
};

export const getBandStatus = () => {
  return callApi().get(
    `/user/recentBandData?memberSeq=${
      GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()
    }`,
  );
};

import { MathRound } from './UseFunction';
import i18next from 'i18next';

// 로그인 할 때 입력한 매장 Company Sequence
export const GET_BROWSER_LANGUAGE = navigator.language || navigator.userLanguage;
export const GET_DEFAULT_PASSWORD = 'fitrust1!';
export const GET_TIMEZONE = Math.abs(new Date().getTimezoneOffset() / 60);
export const GET_BROWSER_LANGUAGE_CODE = GET_BROWSER_LANGUAGE.split('-')[0];
export const GET_BROWSER_COUNTRY_CODE = GET_BROWSER_LANGUAGE.split('-')[1];
export const GET_LOGIN_INFO = () => { return sessionStorage.getItem('login') && JSON.parse(sessionStorage.getItem('login')).profile};
export const GET_LANGUAGE_CODE = () => { return sessionStorage.getItem('login') ? JSON.parse(sessionStorage.getItem('login')).lang : GET_BROWSER_LANGUAGE_CODE };
export const GET_COMPSEQ = () => { return sessionStorage.getItem('compSeq') };
export const GET_MEMBERSEQ = () => { return sessionStorage.getItem('login') && JSON.parse(sessionStorage.getItem('login')).profile.memberSeq };
export const GET_SETTING_MANAGER = () => { return sessionStorage.getItem('login') && (JSON.parse(sessionStorage.getItem('login')).manager && JSON.parse(sessionStorage.getItem('login')).manager.name) };
export const GET_MANAGERSEQ = () => { return sessionStorage.getItem('login') && (JSON.parse(sessionStorage.getItem('login')).manager && JSON.parse(sessionStorage.getItem('login')).manager.memberSeq) };
export const GET_MEMBER_INFO = () => { return sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) };
export const GET_MEMBER_TYPE = () => { return sessionStorage.getItem('login') && JSON.parse(sessionStorage.getItem('login')).type; }
export const GET_MEMBERSEQ_FOR_MNG = () => { return sessionStorage.getItem('managerForMseq')}
export const GET_UNIT_WEIGHT = () => { return sessionStorage.getItem('unit') ? ' ' + JSON.parse(sessionStorage.getItem('unit')).weight.toLowerCase() : ' kg';}
export const GET_UNIT_CALORIE = () => { return sessionStorage.getItem('unit') ? JSON.parse(sessionStorage.getItem('unit')).calorie === 'KC' ? ' Cal': ' KJ' : ' Cal';}
export const GET_UNIT_TEMP = () => { return sessionStorage.getItem('unit') ? JSON.parse(sessionStorage.getItem('unit')).temp === 'C' ? ' °C': ' °F' : ' °C';}
export const GET_UNIT_DISTANCE = () => { return sessionStorage.getItem('unit') && JSON.parse(sessionStorage.getItem('unit')).distance}
export const GET_USER_INFO = () => { return sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')) };
export const GET_URL = () => { return window.location.pathname.split('/'); };
export const GET_UNIT_PRESSURE = 'mmHg';
export const GET_UNIT_BLOOD_SUGAR = 'mg/dL';
export const GET_UNIT_PERCENTAGE = ' %';
export const GET_MANUAL_LINK = 'https://onesoftdigm-my.sharepoint.com/:w:/p/dbr_sim/EbJ38qxZXehKq4oFRXoFTYwBkwde8YOrjs3cRZD3Z1u0Xg?e=pmKlcx';
export const GET_CHART_SHADOW = {
  shadowOffsetX: 3,
  shadowOffsetY: 3,
  shadowBlur: 10,
  shadowColor: 'rgba(0, 0, 0, 0.2)',
}

export const SET_KG_TO_LB = kg => Math.round(kg * 2.20462 * 10) / 10;
export const SET_LB_TO_KG = lb => lb / 2.20462;
export const SET_UNIT_WEIGHT = value => {
  return GET_UNIT_WEIGHT() === ' lb' ? SET_KG_TO_LB(value) : MathRound(value, 10);
};

export const SET_CEL_TO_FAH = cel => MathRound((cel * 9 / 5) + 32, 10);
export const SET_UNIT_TEMP = value => {
  return GET_UNIT_TEMP() === ' °F' ? SET_CEL_TO_FAH(value) : value;
}

export const SET_CAL_TO_KJ = cal => MathRound(cal * 4.184, 10);
export const SET_UNIT_CALORIE = value => {
  return GET_UNIT_CALORIE() === ' KJ' ? SET_CAL_TO_KJ(value) : value;
}

export const GET_DEVICE_NAME = Type => {
  switch (Type) {
    case 'SM' :
      return i18next.t('device01');
    case 'FT' :
      return i18next.t('device02');
    case 'FL' :
      return i18next.t('device03');
    case 'FA' :
      return i18next.t('device05');
    default:
      return '';
  }
}

export const GET_LANGUAGE_I18N = () => {
  const lang = (GET_LANGUAGE_CODE() || 'EN').toUpperCase();
  const langCode = lang.substr(0, 2);

  switch (langCode) {
    case 'KO':
      return 'KR';
    case 'EN':
      return 'EN';
    default :
      return 'EN';
  }
}

export const GET_DEVICE_TYPE = Type => {
  switch (Type) {
    case i18next.t('device01') :
      return 'SM';
    case i18next.t('device02') :
      return 'FT';
    case i18next.t('device03'):
      return 'FL';
    case i18next.t('device05'):
      return 'FA';
    default:
      return '';
  }
}

export const GET_DATE = (Type, cDate) => {
  const lastDay = (new Date(cDate.getYear(), cDate.getMonth(), 0)).getDate();
  if(cDate.getDate() > lastDay)
    cDate.setDate(lastDay);

  switch (Type) {
    case 'Start' :
      cDate.setMonth(cDate.getMonth() - 1);
      break;
    case 'Min' :
      cDate.setMonth(cDate.getMonth() - 3);
      break;
    case 'Max' :
      cDate.setMonth(cDate.getMonth() + 3);
      break;
    default :
      break;
  }

  return cDate;
}

export const GET_START_DATE = cDate => { return cDate.setMonth(cDate.getMonth() - 1); }
export const GET_MIN_DATE = cDate => { return cDate.setMonth(cDate.getMonth() - 3); }
export const GET_MAX_DATE = cDate => { return cDate.setMonth(cDate.getMonth() + 3); }

//TODO Url Check

// eslint-disable-next-line no-undef
export const SET_NAVER_LOGIN = new naver.LoginWithNaverId(
  {
    clientId: 'WyJWHtCXEEzMndlIZMJg',
    // callbackUrl: BIZ_IP + ':4000/sns',
    callbackUrl: 'https://t.thefitrus.com/sns',
    // callbackUrl: 'http://localhost:3000/sns',
    isPopup: false, /* 팝업을 통한 연동처리 여부 */
    loginButton: {color: '', type: 1, height: 35} /* 로그인 버튼의 타입을 지정 */
  }
);
const palette = {
    gray: [
        '#f8f9fa',
        '#f1f3f5',
        '#e9ecef',
        '#dee2e6',
        '#ced4da',
        '#adb5bd',
        '#868e96',
        '#495057',
        '#343a40',
        '#212529',
    ],
    cyan: [
        '#b0d2de',
        '#63c7d2'
    ],
    denim: [
        '#ebeaf0',
        '#d0cfe0',
        '#c1bde0',
        '#adaee0',
        '#969fe0',
        '#94a9e0',
        '#8798e0',
        '#5c78e0',
        '#505ae0',
        '#4854e0',
        '#354ae0'
    ],
    MainTheme : '#63c7d2',
    Color_BFP : '#DBBD48',
    ChartCol01 : '#9DAEE8',
    ChartCol02 : '#5EDBCB',
    ChartCol03 : '#68E858',
    ChartCol04 : '#78ba66',
    ChartCol01Alpha : 'rgba(157, 174, 232, 0.5)',
    ChartCol02Alpha : 'rgba(94, 219, 203, 0.5)',
    ChartCol03Alpha : 'rgba(104, 232, 88, 0.5)',
    ChartBodyTrend01: '#8a86ff',
    ChartBodyTrend01Alpha: 'rgba(138,134,255,0.3)',
    ChartBodyTrend02: 'rgb(255, 115, 115)',
    ChartBodyTrend03: '#dae081',
    ChartBodyTrend04: '#6c62a9',
    ChartBodyTrend05: '#ea8859',
    ChartBodyTrend06: '#424cce',
    ChartBodyTrend07: 'rgba(125,157,232,0.45)',
    ChartBodyTrend08: '#72c597',
    ChartBodyTrend09: '#77c0ca',
    ChartBodyTrend10: '#e87d7d',
};

export const DftColor = '#63c7d2';
export const MenuColor = '#66abdd';
export const DngColor = '#ef4a4a';
export const MaleColor = '#66abdd';
export const FemaleColor = '#f57878';
export const AdminColor = '#35343a';

export default palette;

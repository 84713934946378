import React, { useEffect, useState } from 'react';
import AdminTemplate from '../../view/admin/layout/AdminTemplate';
import AdminCompanyDetail from '../../view/admin/AdminCompanyDetail';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_BODY_COMP, GET_GOAL_ACHIEVE,
  GET_MISSION_RATE, GET_SCATTER_PLOT,
  GET_TOTAL_AGE,
  GET_TOTAL_USER_GENDER,
  GET_TOTAL_WEIGHT,
} from '../../modules/manager/DashModules';

const AdminDetailController = props => {
  const { index } = props.match.params;
  const dispatch = useDispatch();
  const [period, setPeriod] = useState({
    type: '1 month',
    key: '',
  });

  const {totalUserGender, totalAge, totalWeight, scatterPlot, bodyComp, goalAchieve, missionRate} = useSelector(({dash}) => ({
    totalUserGender : dash.totalUserGender,
    totalAge: dash.totalAge,
    totalWeight: dash.totalWeight,
    scatterPlot: dash.scatterPlot,
    bodyComp : dash.bodyComp,
    goalAchieve : dash.goalAchieve,
    missionRate : dash.missionRate,
  }))

  const onChange = e => {
    setPeriod({
      key : e.target.name,
      type : e.target.name
    });
  };

  useEffect(() => {
    dispatch(GET_TOTAL_USER_GENDER(index));
    dispatch(GET_TOTAL_AGE(index));
    dispatch(GET_MISSION_RATE(index));
    dispatch(GET_TOTAL_WEIGHT({ period: period.type, index }));
    dispatch(GET_SCATTER_PLOT({index}));
    dispatch(GET_BODY_COMP({index}));
    dispatch(GET_GOAL_ACHIEVE({index}));

  }, [dispatch, period]);


  return (
    <AdminTemplate>
      <AdminCompanyDetail
        index={index}
        onChange={onChange}
        ttlAgeData={totalAge}
        wtData={totalWeight}
        sctPlotData={scatterPlot}
        bodyCompData={bodyComp}
        missionData={missionRate}
        goalAchieveData={goalAchieve}
        ttlUserAndGenderData={totalUserGender}
      />
    </AdminTemplate>
  )
};

export default AdminDetailController;
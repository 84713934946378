import { createAction, handleActions} from 'redux-actions';
import { initialState } from '../initialState';

const ACTION_SET_ALERT = 'alert/SHOW_ALERT';
const ACTION_RESET_ALERT = 'alert/RESET_ALERT';

export const RESET_ALERT = createAction(ACTION_RESET_ALERT);
export const SET_ALERT = createAction(
  ACTION_SET_ALERT,
  ({ header, type, text, subText, check, submit, reload }) =>
    ({ header, type, text, subText, check, submit, reload }),
);

const alertModule = handleActions({
  [ACTION_SET_ALERT]: (state, { payload: { header, type, text, subText, check, submit, reload } }) => ({
    alertConfig: {
      header, text, subText, type, check, submit, reload, visible: true,
    },
  }),
  [ACTION_RESET_ALERT]: (state, action) => ({
    ...state,
    ['alertConfig']: initialState['alertConfig'],
  }),
}, initialState);

export default alertModule;
import React from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import Input from '../../components/Input';
import { StyleButton } from '../../components/Button';
import { AdminColor } from '../../../lib/styles/palette';

const PwChangeForm = styled.div`
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    outline: none;
    padding: .5rem 0;
    width: 100%;
    font-size: 14px;

    &::placeholder {
      color: #ccc;
      font-size: 14px;
    }
  }

  div {
    span {
      font-size: 11px;
      display: block;
    }
  }
`;

const Error = styled.div`
  color: #fd4c4c;
  font-size: 12px;
  margin: 0.5rem 0;
  min-height: 20px;

  &.success {
    color: #23c823;
  }
`;

const Header = styled.div`
  font-size: 1rem;
  padding: 1rem;
  background-color: ${AdminColor};
  color: #FFF;
`;

const Body = styled.div`
  padding: 2rem 1.5rem;
`;

const AdminChangePw = props => {
  const pwInfo = props.pwInfo;

  return (
    <PwChangeForm>
      <Header>
        비밀번호 변경
      </Header>
      
      <Body>
        <div>
          <p>
            {i18next.t('MngMyInfoPwChg01')}
          </p>

          <div >
            <Input
              name="oldPw"
              type="password"
              value={pwInfo.oldPw}
              onChange={props.onChange}
              placeHolder={i18next.t('MngMyInfoPwChg01')}
              onBlur={props.onCheck}
            />
          </div>

          <Error className={props.onCheckStatus === 200 ? 'success' : ''}>
            {props.error.cPwError}
          </Error>
        </div>

        <div>
          <p>
            {i18next.t('MngMyInfoPwChg02')}
          </p>

          <div className="mb-3">
            <Input
              name="newPw"
              type="password"
              value={pwInfo.newPw}
              onChange={props.onChange}
              placeHolder={i18next.t('MngMyInfoPwChg02')}
            />
          </div>

          <div className="mb-3">
            <Input
              name="newPwRe"
              type="password"
              value={pwInfo.newPwRe}
              onChange={props.onChange}
              placeHolder={i18next.t('MngMyInfoPwChg03')}
            />
          </div>

          <Error>
            {props.error.nPwError}
          </Error>
        </div>

        <div className="my-3">
          <span>* {i18next.t('ErrorPwType')}</span>
        </div>

        <StyleButton
          text={i18next.t('ButtonChangePass')}
          onClick={props.onSubmit}
          background={AdminColor}
        />
      </Body>
    </PwChangeForm>
  )
};

export default AdminChangePw;
import React from 'react';
import styled from 'styled-components';
import { BsCircleFill } from 'react-icons/bs';
import { FaArrowUp, FaArrowRight } from 'react-icons/fa';
import i18next from 'i18next';
import palette, { FemaleColor, MaleColor } from '../../lib/styles/palette';
import CheckBox from './CheckBox';

const ChartLegendBody = styled.div`
  display: flex;
  width: 100%;
  padding: .75rem;
  
  p span {
    padding: 0 10px;
  }
  
  .trend ul li {
    font-size: 0.7rem!important;
  }
  
  ul {
    display: flex;
    margin: 0!important;
    align-items: center;
    
    li {
      width: auto!important;
      font-size: 0.8rem!important;
      margin-right: .5rem;
      
      &.maleLgd {color: ${MaleColor}}
      &.femaleLgd {color: ${FemaleColor}}
      &.bfpLgd {color: ${palette.Color_BFP}}
      &.firLgd {color: ${palette.ChartCol01}}
      &.secLgd {color: ${palette.ChartCol02}}
      &.thdLgd {color: ${palette.ChartCol03}}
      &.forLgd {color: ${palette.ChartBodyTrend04}}
      &.otherLgd {color: ${palette.ChartCol04}; font-size: 18px}
    }
  }
  
  input[type=checkbox] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #bcbcbc;
    cursor: pointer;
    
    &:checked:after {
      background-color: #eaeaea!important;
    }
    
  }
  
  input[type=checkbox]:checked {
  }
`;

const ChartLegend = () => {
  return (
    <ChartLegendBody >
      <ul>
        <li className="icons firLgd"><BsCircleFill /></li>
        <li>{i18next.t('GenderF')}</li>
        <li className="icons secLgd"><BsCircleFill /></li>
        <li>{i18next.t('GenderM')}</li>
      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendBodyComp = ({legendLength}) => {
  return (
    <ChartLegendBody>
      <ul>
        <li className="icons firLgd"><BsCircleFill /></li>
        <li>{i18next.t('AnalysisHeader02')}</li>
        <li className="icons secLgd"><BsCircleFill /></li>
        <li>{i18next.t('AnalysisHeader03')}</li>
        {legendLength > 2 &&
          <>
            <li className="icons thdLgd"><BsCircleFill /></li>
            <li>{i18next.t('OtherCtg05')}</li>
          </>
        }
      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendGoal = ({legendLength}) => {
  return (
    <ChartLegendBody>
      <ul>
        <li className="icons bfpLgd"><BsCircleFill /></li>
        <li>{i18next.t('AnalysisHeader01')}</li>
        <li className="icons secLgd"><BsCircleFill /></li>
        <li>{i18next.t('AnalysisHeader03')}</li>
        {legendLength > 2 &&
        <>
          <li className="icons thdLgd"><BsCircleFill /></li>
          <li>{i18next.t('OtherCtg05')}</li>
        </>
        }
      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendScatter = () => {
  return (
    <ChartLegendBody>
      <ul>
        <li className="icons maleLgd"><BsCircleFill /></li>
        <li>{i18next.t('GenderM')}</li>
        <li className="icons femaleLgd"><BsCircleFill /></li>
        <li>{i18next.t('GenderF')}</li>
        <li className="icons otherLgd"><FaArrowUp /></li>
        <li>{i18next.t('AnalysisHeader03')}</li>
        <li className="icons otherLgd"><FaArrowRight /></li>
        <li>{i18next.t('AnalysisHeader02')}</li>
      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendMission = () => {
  return (
    <ChartLegendBody>
      <ul>
        <li className="icons forLgd"><BsCircleFill /></li>
        <li>{i18next.t('OtherCtg06')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendTrend = props => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className="px-0"><CheckBox uKey={1} color={palette.ChartBodyTrend01} onChange={props.onChange} checked={!props.TrendType.chk1}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend01}/></li>*/}
        <li>{i18next.t('AnalysisHeader01')}</li>
        <li className="px-0"><CheckBox uKey={2} color={palette.ChartBodyTrend02} onChange={props.onChange} checked={!props.TrendType.chk2}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>*/}
        <li>{i18next.t('AnalysisHeader02')}</li>
        <li className="px-0"><CheckBox uKey={3} color={palette.ChartBodyTrend03} onChange={props.onChange} checked={!props.TrendType.chk3}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend03}/></li>*/}
        <li>{i18next.t('AnalysisHeader03')}</li>
        <li className="px-0"><CheckBox uKey={4} color={palette.ChartBodyTrend04} onChange={props.onChange} checked={!props.TrendType.chk4}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend04}/></li>*/}
        <li>{i18next.t('AnalysisHeader04')}</li>
        <li className="px-0"><CheckBox uKey={5} color={palette.ChartBodyTrend05} onChange={props.onChange} checked={!props.TrendType.chk5}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend05}/></li>*/}
        <li>{i18next.t('AnalysisHeader05')}</li>
        <li className="px-0"><CheckBox uKey={6} color={palette.ChartBodyTrend06} onChange={props.onChange} checked={!props.TrendType.chk6}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend06}/></li>*/}
        <li>{i18next.t('AnalysisHeader06')}</li>
        <li className="px-0"><CheckBox uKey={7} color={palette.ChartBodyTrend07} onChange={props.onChange} checked={!props.TrendType.chk7}/></li>
        {/*<li className=""><BsCircleFill color={palette.ChartBodyTrend07}/></li>*/}
        <li>{i18next.t('AnalysisHeader10')}</li>
        <li className="px-0"><CheckBox uKey={8} color={palette.ChartBodyTrend08} onChange={props.onChange} checked={!props.TrendType.chk8}/></li>
        <li>{i18next.t('AnalysisHeader14')}</li>
        <li className="px-0"><CheckBox uKey={9} color={palette.ChartBodyTrend09} onChange={props.onChange} checked={!props.TrendType.chk9}/></li>
        <li>{i18next.t('AnalysisHeader15')}</li>
        <li className="px-0"><CheckBox uKey={10} color={palette.ChartBodyTrend10} onChange={props.onChange} checked={!props.TrendType.chk10}/></li>
        <li>{i18next.t('AnalysisHeader16')}</li>

      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendBpm = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('BpmHeader01')}</li>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend01}/></li>
        <li>{i18next.t('BpmHeader02')}</li>
      </ul>
    </ChartLegendBody>
  );
};

export const ChartLegendStress = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('StressHeader01')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendRun = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('TotalDistance')} (KM)</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendSteps = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('StepsHeader01')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendTemperature = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('TempHeader01')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendBloodPressure = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('systolic')}</li>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend01}/></li>
        <li>{i18next.t('diastolic')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export const ChartLegendBloodSugar = () => {
  return (
    <ChartLegendBody className="trend">
      <ul>
        <li className=""><BsCircleFill color={palette.ChartBodyTrend02}/></li>
        <li>{i18next.t('BloodSugar')}</li>
      </ul>
    </ChartLegendBody>
  );
}

export default ChartLegend;
import { createAction, handleActions} from 'redux-actions';

const START_LOADING = 'RequestLoading/START_LOADING';
const FINISH_LOADING = 'RequestLoading/FINISH_LOADING';

export const startLoading = createAction(
  START_LOADING,
  requestType => requestType
);

export const finishLoading = createAction(
  FINISH_LOADING,
  requestType => requestType
);

const initialState = {
  loading : false,
  loadCheck : false
};

const requestLoading = handleActions({
  [START_LOADING]: () => {
    initialState['loading'] = true
  },
  [FINISH_LOADING]: () => ({
    loading : false,
    loadCheck : true
  }),
}, initialState);

export default requestLoading;
import { createAction, handleActions } from 'redux-actions';
import createSaga, { createActionTypes } from '../../utils/Request/RequestCreateSaga';
import * as MISSION_API from '../../api/MissionApi';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { initialState } from '../initialState';

const ACTION_CHANGE_MYINFO = 'mission/ACTION_CHANGE_MYINFO';
const ACTION_RESET_FORM = 'mission/ACTION_RESET_FORM';
const ACTION_MISSION_DETAIL = 'mission/ACTION_MISSION_DETAIL';
const [ACTION_GET_MISSION, ACTION_GET_MISSION_SUCCESS] = createActionTypes('mission/ACTION_GET_MISSION');
const [ACTION_GET_MISSION_NOW, ACTION_GET_MISSION_NOW_SUCCESS] = createActionTypes('mission/ACTION_GET_MISSION_NOW');
const [ACTION_GET_MISSION_DOWNLOAD_LIST, ACTION_GET_MISSION_DOWNLOAD_LIST_SUCCESS] = createActionTypes('mission/ACTION_GET_MISSION_DOWNLOAD_LIST');
const [ACTION_SET_MISSION, ACTION_SET_MISSION_SUCCESS] = createActionTypes('mission/ACTION_SET_MISSION');
const [ACTION_GET_TOPIC, ACTION_GET_TOPIC_SUCCESS] = createActionTypes('mission/ACTION_GET_TOPIC');
const [ACTION_GET_RPT_LIST, ACTION_GET_RPT_LIST_SUCCESS] = createActionTypes('mission/ACTION_GET_RPT_LIST');
const [ACTION_GET_RPT_ACHIEVE_LIST, ACTION_GET_RPT_ACHIEVE_LIST_SUCCESS] = createActionTypes('mission/ACTION_GET_RPT_ACHIEVE_LIST');
const [ACTION_SET_TOPIC, ACTION_SET_TOPIC_SUCCESS] = createActionTypes('mission/ACTION_SET_TOPIC');
const [ACTION_GET_NOTE, ACTION_GET_NOTE_SUCCESS] = createActionTypes('mission/ACTION_GET_NOTE');
const [ACTION_SET_NOTE, ACTION_SET_NOTE_SUCCESS] = createActionTypes('mission/ACTION_SET_NOTE');
const [ACTION_DELETE_NOTE, ACTION_DELETE_NOTE_SUCCESS] = createActionTypes('mission/ACTION_DELETE_NOTE');
const [ACTION_GET_MISSION_COMMENT_LIST, ACTION_GET_MISSION_COMMENT_LIST_SUCCESS] = createActionTypes('mission/ACTION_GET_MISSION_COMMENT_LIST');
const [ACTION_SET_MISSION_COMMENT, ACTION_SET_MISSION_COMMENT_SUCCESS] = createActionTypes('mission/ACTION_SET_MISSION_COMMENT');
const [ACTION_DELETE_MISSION_COMMENT, ACTION_DELETE_MISSION_COMMENT_SUCCESS] = createActionTypes('mission/ACTION_DELETE_MISSION_COMMENT');

const SAGA_GET_MISSION = createSaga(ACTION_GET_MISSION, MISSION_API.getMission);
const SAGA_GET_MISSION_NOW = createSaga(ACTION_GET_MISSION_NOW, MISSION_API.getMissionNow);
const SAGA_GET_MISSION_DOWNLOAD_LIST = createSaga(ACTION_GET_MISSION_DOWNLOAD_LIST, MISSION_API.getMissionDownloadList);
const SAGA_SET_MISSION = createSaga(ACTION_SET_MISSION, MISSION_API.setMission, true);
const SAGA_GET_RPT_LIST = createSaga(ACTION_GET_RPT_LIST, MISSION_API.getRptList, false, true);
const SAGA_GET_RPT_ACHIEVE_LIST = createSaga(ACTION_GET_RPT_ACHIEVE_LIST, MISSION_API.getRptAchieveList);
const SAGA_GET_TOPIC = createSaga(ACTION_GET_TOPIC, MISSION_API.getTopic);
const SAGA_SET_TOPIC = createSaga(ACTION_SET_TOPIC, MISSION_API.setTopic, true);
const SAGA_GET_NOTE = createSaga(ACTION_GET_NOTE, MISSION_API.getNote);
const SAGA_SET_NOTE = createSaga(ACTION_SET_NOTE, MISSION_API.setNote, true);
const SAGA_DELETE_NOTE = createSaga(ACTION_DELETE_NOTE, MISSION_API.deleteNote, true);
const SAGA_GET_MISSION_COMMENT_LIST = createSaga(ACTION_GET_MISSION_COMMENT_LIST, MISSION_API.getmissionComment, false, false);
const SAGA_SET_MISSION_COMMENT = createSaga(ACTION_SET_MISSION_COMMENT, MISSION_API.setMissionComment, false, false);
const SAGA_DELETE_MISSION_COMMENT = createSaga(ACTION_DELETE_MISSION_COMMENT, MISSION_API.deleteMissionComment, true);

export function* missionSaga() {
  yield takeLatest(ACTION_GET_MISSION, SAGA_GET_MISSION);
  yield takeLatest(ACTION_GET_MISSION_NOW, SAGA_GET_MISSION_NOW);
  yield takeLatest(ACTION_GET_MISSION_DOWNLOAD_LIST, SAGA_GET_MISSION_DOWNLOAD_LIST);
  yield takeLatest(ACTION_GET_RPT_LIST, SAGA_GET_RPT_LIST);
  yield takeLatest(ACTION_GET_RPT_ACHIEVE_LIST, SAGA_GET_RPT_ACHIEVE_LIST);
  yield takeLatest(ACTION_SET_MISSION, SAGA_SET_MISSION);
  yield takeLatest(ACTION_GET_TOPIC, SAGA_GET_TOPIC);
  yield takeLatest(ACTION_SET_TOPIC, SAGA_SET_TOPIC);
  yield takeLatest(ACTION_GET_NOTE, SAGA_GET_NOTE);
  yield takeLatest(ACTION_SET_NOTE, SAGA_SET_NOTE);
  yield takeLatest(ACTION_DELETE_NOTE, SAGA_DELETE_NOTE);
  yield takeLatest(ACTION_GET_MISSION_COMMENT_LIST, SAGA_GET_MISSION_COMMENT_LIST);
  yield takeLatest(ACTION_SET_MISSION_COMMENT, SAGA_SET_MISSION_COMMENT);
  yield takeLatest(ACTION_DELETE_MISSION_COMMENT, SAGA_DELETE_MISSION_COMMENT);
}

export const GET_TOPIC = createAction(ACTION_GET_TOPIC);
export const GET_NOTE = createAction(ACTION_GET_NOTE, title => title);
export const GET_MISSION = createAction(ACTION_GET_MISSION);
export const GET_MISSION_NOW = createAction(ACTION_GET_MISSION_NOW, page => page);
export const GET_MISSION_DOWNLOAD_LIST = createAction(ACTION_GET_MISSION_DOWNLOAD_LIST, form => form)
export const GET_RPT_LIST = createAction(ACTION_GET_RPT_LIST, name => name);
export const GET_RPT_ACHIEVE_LIST = createAction(ACTION_GET_RPT_ACHIEVE_LIST, seq => seq);
export const GET_MISSION_COMMENT_LIST = createAction(ACTION_GET_MISSION_COMMENT_LIST, form => form);
export const SET_MISSION = createAction(ACTION_SET_MISSION, form => form);
export const SET_MISSION_DETAIL = createAction(ACTION_MISSION_DETAIL, form => form);
export const SET_MISSION_COMMENT = createAction(ACTION_SET_MISSION_COMMENT, form => form);
export const SET_NOTE = createAction(ACTION_SET_NOTE, note => note);
export const SET_TOPIC = createAction(ACTION_SET_TOPIC, topic => topic);
export const CHANGE_MISSION = createAction(ACTION_CHANGE_MYINFO, ({form, name, value}) => ({form, name, value}));
export const DELETE_NOTE = createAction(ACTION_DELETE_NOTE, seq => seq)
export const DELETE_MISSION_COMMENT = createAction(ACTION_DELETE_MISSION_COMMENT, seq => seq)
export const RESET_MISSION = createAction(ACTION_RESET_FORM, form => form);

export default handleActions({
  [ACTION_MISSION_DETAIL]: (state, { payload: { data }}) => {
  },
  [ACTION_CHANGE_MYINFO]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [ACTION_RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_GET_MISSION_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    mission: data.list,
  }),
  [ACTION_GET_MISSION_NOW_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionNow: data,
  }),
  [ACTION_GET_MISSION_DOWNLOAD_LIST_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionDownloadList: data.list,
  }),
  [ACTION_GET_TOPIC_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnTopicList: data.list,
  }),
  [ACTION_GET_NOTE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnNoteList: data,
  }),
  [ACTION_GET_RPT_LIST_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    rptList: data,
  }),
  [ACTION_GET_RPT_ACHIEVE_LIST_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    rptAchieveList: data.list,
  }),
  [ACTION_SET_MISSION_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnSuccess: data === 200,
  }),
  [ACTION_SET_TOPIC_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnTopicSuccess: data === 200,
  }),
  [ACTION_SET_NOTE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnNoteSuccess: data === 200,
  }),
  [ACTION_DELETE_NOTE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnNoteDltSuccess: data === 200,
  }),
  [ACTION_GET_MISSION_COMMENT_LIST_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnCmtList: data.list,
  }),
  [ACTION_SET_MISSION_COMMENT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnCmtSuccess: data,
  }),
  [ACTION_DELETE_MISSION_COMMENT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    msnCmtDelSuccess: data === 200,
  }),
}, [initialState]);
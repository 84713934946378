import { createAction, handleActions, ActionFunction0, Action } from 'redux-actions';
import createSaga, { createActionTypes} from '../../utils/Request/RequestCreateSaga';
import * as MEMBER_API from '../../api/MemberApi';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { initialState } from '../initialState';
import { CheckEmpty } from '../../utils/UseFunc/UseFunction';

// 액션 타입정의
const ACTION_CHANGE_MYINFO = 'member/ACTION_CHANGE_MYINFO';
const ACTION_CHANGE_FORM = 'member/ACTION_CHANGE_FORM';
const ACTION_RESET_FORM = 'member/ACTION_RESET_FORM';
const ACTION_SET_MANAGER_INFO = 'member/ACTION_SET_MANAGER_INFO'
const [ACTION_RESET_PASSWORD, ACTION_RESET_PASSWORD_SUCCESS] = createActionTypes('member/ACTION_RESET_PASSWORD');
const [ACTION_GET_MYINFO, ACTION_GET_MYINFO_SUCCESS] = createActionTypes('member/ACTION_GET_INFO');
const [ACTION_GET_MANAGERINFO, ACTION_GET_MANAGERINFO_SUCCESS] = createActionTypes('member/ACTION_GET_MANAGERINFO');
const [ACTION_GET_RANK, ACTION_GET_RANK_SUCCESS] = createActionTypes('member/ACTION_GET_RANK');
const [ACTION_GET_MANAGER, ACTION_GET_MANAGER_SUCCESS] = createActionTypes('member/ACTION_GET_MANAGER');
const [ACTION_GET_MEMBER, ACTION_GET_MEMBER_SUCCESS] = createActionTypes('member/ACTION_GET_MEMBER');
const [ACTION_GET_MEMBER_NEW, ACTION_GET_MEMBER_SUCCESS_NEW] = createActionTypes('member/ACTION_GET_MEMBER_NEW')
const [ACTION_GET_MEMBER_PROFILE, ACTION_GET_MEMBER_PROFILE_SUCCESS] = createActionTypes('member/ACTION_GET_MEMBER_PROFILE');
const [ACTION_SET_MYINFO, ACTION_SET_MYINFO_SUCCESS] = createActionTypes('member/ACTION_SET_INFO');
const [ACTION_SET_COMPLOGO, ACTION_SET_COMPLOGO_SUCCESS] = createActionTypes('member/ACTION_SET_COMPLOGO');
const [ACTION_SET_PROMOTIONLOGO, ACTION_SET_PROMOTIONLOGO_SUCCESS] = createActionTypes('member/ACTION_SET_PROMOTIONLOGO');
const [ACTION_EDIT_MEMBER, ACTION_EDIT_MEMBER_SUCCESS] = createActionTypes('member/ACTION_EDIT_MEMBER');
const [ACTION_CREATE_MEMBER, ACTION_CREATE_MEMBER_SUCCESS] = createActionTypes('member/ACTION_CREATE_MEMBER');
const [ACTION_DELETE_MEMBER, ACTION_DELETE_MEMBER_SUCCESS] = createActionTypes('member/ACTION_DELETE_MEMBER');
const [ACTION_DELETE_MEMBER_FOR_MANAGER, ACTION_DELETE_MEMBER_FOR_MANAGER_SUCCESS] = createActionTypes('member/ACTION_DELETE_MEMBER_FOR_MANAGER');
const [ACTION_CHANGE_PASSWORD, ACTION_CHANGE_PASSWORD_SUCCESS, ACTION_CHANGE_PASSWORD_FAILURE] = createActionTypes('member/ACTION_CHANGE_PASSWORD');
const [ACTION_GET_MEMBER_UNIT, ACTION_GET_MEMBER_UNIT_SUCCESS] = createActionTypes('member/ACTION_GET_MEMBER_UNIT');
const [ACTION_CHANGE_MEMBER_UNIT, ACTION_CHANGE_MEMBER_UNIT_SUCCESS] = createActionTypes('member/ACTION_CHANGE_MEMBER_UNIT');
const [ACTION_CHECK_ID, ACTION_CHECK_ID_SUCCESS] = createActionTypes('member/ACTION_CHECK_ID');
const [ACTION_CHECK_PASSWORD, ACTION_CHECK_PASSWORD_SUCCESS] = createActionTypes('member/ACTION_CHECK_PASSWORD');

// Saga 생성
const SAGA_GET_MYINFO = createSaga(ACTION_GET_MYINFO, MEMBER_API.getMyInfo);
const SAGA_GET_MANAGERINFO = createSaga(ACTION_GET_MANAGERINFO, MEMBER_API.getManagerInfo);
const SAGA_GET_RANK = createSaga(ACTION_GET_RANK, MEMBER_API.getRank);
const SAGA_GET_MANAGER = createSaga(ACTION_GET_MANAGER, MEMBER_API.getManager);
const SAGA_GET_MEMBER = createSaga(ACTION_GET_MEMBER, MEMBER_API.getMember, false, false);
const SAGA_GET_MEMBER_NEW = createSaga(ACTION_GET_MEMBER_NEW, MEMBER_API.getMemberNew, false, false)
const SAGA_GET_MEMBER_PROFILE = createSaga(ACTION_GET_MEMBER_PROFILE, MEMBER_API.getMemberProfile);
const SAGA_SET_MYINFO = createSaga(ACTION_SET_MYINFO, MEMBER_API.setMyInfo);
const SAGA_SET_COMPLOGO = createSaga(ACTION_SET_COMPLOGO, MEMBER_API.setCompLogo);
const SAGA_SET_PROMOTIONLOGO = createSaga(ACTION_SET_PROMOTIONLOGO, MEMBER_API.setPromotionLogo, true);
const SAGA_RESET_PASSWORD = createSaga(ACTION_RESET_PASSWORD, MEMBER_API.resetPassword, true);
const SAGA_CREATE_MEMBER = createSaga(ACTION_CREATE_MEMBER, MEMBER_API.createMember, true);
const SAGA_EDIT_MEMBER = createSaga(ACTION_EDIT_MEMBER, MEMBER_API.editMember, true);
const SAGA_DELETE_MEMBER = createSaga(ACTION_DELETE_MEMBER, MEMBER_API.deleteMember, true);
const SAGA_DELETE_MEMBER_FOR_MANAGER = createSaga(ACTION_DELETE_MEMBER_FOR_MANAGER, MEMBER_API.deleteMember, true);
const SAGA_CHANGE_PASSWORD = createSaga(ACTION_CHANGE_PASSWORD, MEMBER_API.changePassword);
const SAGA_GET_MEMBER_UNIT = createSaga(ACTION_GET_MEMBER_UNIT, MEMBER_API.getUnit);
const SAGA_CHANGE_MEMBER_UNIT = createSaga(ACTION_CHANGE_MEMBER_UNIT, MEMBER_API.changeUnit, true);
const SAGA_CHECK_ID = createSaga(ACTION_CHECK_ID, MEMBER_API.checkId, true, false);
const SAGA_CHECK_PASSWORD = createSaga(ACTION_CHECK_PASSWORD, MEMBER_API.checkPassword, true);

// Data 저장 액션함수
export const GET_MYINFO = createAction(ACTION_GET_MYINFO);
export const GET_MANAGERINFO = createAction(ACTION_GET_MANAGERINFO);
export const GET_RANK = createAction(ACTION_GET_RANK, ({ range, type, mSeq }) => ({ range, type, mSeq }));
export const GET_MANAGER = createAction(ACTION_GET_MANAGER);
export const GET_MEMBER = createAction(ACTION_GET_MEMBER, form => form);

/**
 * @typedef {Object} GetMemberNewPaylod
 * @property {Number} page
 * @property {Number} rowsPerPage
 * @property {'nickname' | 'date'} [sort]
 * @property {'desc' | 'asc'} [desc]
 * @property {String} [name]
 * @property {Number} mngIndex
 */
export const GET_MEMBER_NEW = createAction(
  ACTION_GET_MEMBER_NEW,
  /**
   * @param { GetMemberNewPaylod } form
   * @returns { GetMemberNewPaylod } returned data
   */
  // eslint-disable-next-line arrow-parens
  (form) => form
)

export const GET_MEMBER_PROFILE = createAction(ACTION_GET_MEMBER_PROFILE, mSeq => mSeq);
// export const GET_MEMBER = createAction(ACTION_GET_MEMBER);
export const RESET_FORM = createAction(ACTION_RESET_FORM, form => form);
export const RESET_PASSWORD = createAction(ACTION_RESET_PASSWORD, mSeq => mSeq)
export const SET_MYINFO = createAction(ACTION_SET_MYINFO, myInfo => myInfo)
export const SET_COMPLOGO = createAction(ACTION_SET_COMPLOGO, urlLogo => urlLogo)
export const SET_PROMOTIONLOGO = createAction(ACTION_SET_PROMOTIONLOGO, img => img)
export const SET_MANAGER_INFO = createAction(ACTION_SET_MANAGER_INFO, form => form);
export const CREATE_MEMBER = createAction(ACTION_CREATE_MEMBER, form => form)
export const EDIT_MEMBER = createAction(ACTION_EDIT_MEMBER, form => form)
export const DELETE_MEMBER = createAction(ACTION_DELETE_MEMBER, mSeq => mSeq)
export const DELETE_MEMBER_FOR_MANAGER = createAction(ACTION_DELETE_MEMBER_FOR_MANAGER, mSeq => mSeq)
export const CHANGE_FORM = createAction(ACTION_CHANGE_FORM, ({ form, value }) => ({ form, value }));
export const CHANGE_MYINFO = createAction(ACTION_CHANGE_MYINFO, ({ form, name, value }) => ({ form, name, value }));
export const CHANGE_PASSWORD = createAction(ACTION_CHANGE_PASSWORD, form => form);
export const GET_MEMBER_UNIT = createAction(ACTION_GET_MEMBER_UNIT);
export const CHANGE_MEMBER_UNIT = createAction(ACTION_CHANGE_MEMBER_UNIT, form => form);
export const CHECK_ID = createAction(ACTION_CHECK_ID, id => id);
export const CHECK_PASSWORD = createAction(ACTION_CHECK_PASSWORD, form => form);

// 루트 리듀서 생성
export function* memberSaga() {
  yield takeLatest(ACTION_GET_MYINFO, SAGA_GET_MYINFO);
  yield takeLatest(ACTION_GET_MANAGERINFO, SAGA_GET_MANAGERINFO);
  yield takeLatest(ACTION_GET_RANK, SAGA_GET_RANK);
  yield takeLatest(ACTION_GET_MANAGER, SAGA_GET_MANAGER);
  yield takeLatest(ACTION_GET_MEMBER, SAGA_GET_MEMBER);
  yield takeLatest(ACTION_GET_MEMBER_NEW, SAGA_GET_MEMBER_NEW);
  yield takeLatest(ACTION_GET_MEMBER_PROFILE, SAGA_GET_MEMBER_PROFILE);
  yield takeLatest(ACTION_SET_MYINFO, SAGA_SET_MYINFO);
  yield takeLatest(ACTION_SET_COMPLOGO, SAGA_SET_COMPLOGO);
  yield takeLatest(ACTION_SET_PROMOTIONLOGO, SAGA_SET_PROMOTIONLOGO);
  yield takeLatest(ACTION_RESET_PASSWORD, SAGA_RESET_PASSWORD);
  yield takeLatest(ACTION_CREATE_MEMBER, SAGA_CREATE_MEMBER);
  yield takeLatest(ACTION_EDIT_MEMBER, SAGA_EDIT_MEMBER);
  yield takeLatest(ACTION_DELETE_MEMBER, SAGA_DELETE_MEMBER);
  yield takeLatest(ACTION_DELETE_MEMBER_FOR_MANAGER, SAGA_DELETE_MEMBER_FOR_MANAGER);
  yield takeLatest(ACTION_CHANGE_PASSWORD, SAGA_CHANGE_PASSWORD);
  yield takeLatest(ACTION_GET_MEMBER_UNIT, SAGA_GET_MEMBER_UNIT);
  yield takeLatest(ACTION_CHANGE_MEMBER_UNIT, SAGA_CHANGE_MEMBER_UNIT);
  yield takeLatest(ACTION_CHECK_ID, SAGA_CHECK_ID);
  yield takeLatest(ACTION_CHECK_PASSWORD, SAGA_CHECK_PASSWORD);
}

export default handleActions({
  [ACTION_CHANGE_MYINFO]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [ACTION_CHANGE_FORM]: (state, { payload: { form, value } }) => ({
    ...state,
    [form]: value,
  }),
  [ACTION_RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_GET_MYINFO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    myInfo: data,
  }),
  [ACTION_GET_MANAGERINFO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    myInfo: data,
  }),
  [ACTION_GET_RANK_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    myRank: data,
    rankSuccess: true
  }),
  [ACTION_GET_MANAGER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    manager: data.list,
  }),
  [ACTION_GET_MEMBER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    member: data,
  }),
  [ACTION_GET_MEMBER_SUCCESS_NEW]: (state, { payload: { data, total } }) => ({
    ...state,
    member: data,
    memberTotal: total,
  }),
  [ACTION_SET_MANAGER_INFO]: (state, { payload: data }) => ({
    ...state,
    regMember: {
      RegId: data.id,
      RegName: data.name,
      RegEmail: data.email,
      RegGender: data.gender,
      RegMType: 'M',
      RegTel: data.phone,
      RegImgUrl: data.urlProfile,
      type: 'E',
      mSeq: data.memberSeq,
    },
  }),
  [ACTION_GET_MEMBER_PROFILE_SUCCESS]: (state, { payload: {manager, info} }) => ({
    ...state,
    regMember: {
      RegId: info.id,
      RegName: info.name,
      RegEmail: info.email,
      RegGender: info.gender,
      RegMType: 'U',
      RegManager: !CheckEmpty(manager) && manager.memberSeq,
      RegManagerOld: !CheckEmpty(manager) && manager.memberSeq,
      RegTel: info.phone,
      RegImgUrl: info.urlProfile,
      type: 'E',
      mSeq: info.memberSeq,
    },
  }),
  [ACTION_SET_MYINFO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    infoSuccess: data === 200,
  }),
  [ACTION_SET_COMPLOGO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    logoSuccess: data === 200,
  }),
  [ACTION_SET_PROMOTIONLOGO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    promoationLogoSuccess: data === 200,
  }),
  [ACTION_CREATE_MEMBER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    regMemberSuccess: data === 200,
  }),
  [ACTION_EDIT_MEMBER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    mbEdtCode: data,
  }),
  [ACTION_DELETE_MEMBER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    deleteSuccess: data === 200,
  }),
  [ACTION_DELETE_MEMBER_FOR_MANAGER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    deleteForManagerSuccess: data === 200,
  }),
  [ACTION_RESET_PASSWORD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    resetPwSuccess: data === 200,
  }),
  [ACTION_CHANGE_PASSWORD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    changePwSuccess: data === 200,
  }),
  [ACTION_CHANGE_PASSWORD_FAILURE]: (state, { payload: error }) => ({
    ...state,
    changePwError: error
  }),
  [ACTION_GET_MEMBER_UNIT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    memberUnit: {
      height: data.height,
      weight: data.weight,
      distance: data.distance,
      temp: data.temp,
      calorie: data.calorie
    }
  }),
  [ACTION_CHANGE_MEMBER_UNIT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    memberUnitSuccess: data === 200,
  }),
  [ACTION_CHECK_ID_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    checkIdSuccess: data,
  }),
  [ACTION_CHECK_PASSWORD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    checkPasswordSuccess: data
  }),
}, [initialState]);
